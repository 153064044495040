export default {
  deliveryorderdetailList (state) {
    return state.deliveryorderdetailList
  },
  deliveryorderdetail (state) {
    return state.deliveryorderdetail
  },
  deliveryorderdetailTotal (state) {
    return state.deliveryorderdetailTotal
  }
}
