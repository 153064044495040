import contract from './contract'
import contractdetail from './contractdetail'
import contractfee from './contractfee'
import deliveryorder from './deliveryorder'
import deliveryorderdetail from './deliveryorderdetail'
import ladingorder from './ladingorder'
import ladingorderdetail from './ladingorderdetail'
import receiptinfo from './receiptinfo'
import receiptinfodetail from './receiptinfodetail'
import billpay from './billpay'

export default {
  state: {
    ...contract.state,
    ...contractdetail.state,
    ...contractfee.state,
    ...deliveryorder.state,
    ...deliveryorderdetail.state,
    ...ladingorder.state,
    ...ladingorderdetail.state,
    ...receiptinfo.state,
    ...receiptinfodetail.state,
    ...billpay.state
  },
  getters: {
    ...contract.getters,
    ...contractdetail.getters,
    ...contractfee.getters,
    ...deliveryorder.getters,
    ...deliveryorderdetail.getters,
    ...ladingorder.getters,
    ...ladingorderdetail.getters,
    ...receiptinfo.getters,
    ...receiptinfodetail.getters,
    ...billpay.getters
  },
  mutations: {
    ...contract.mutations,
    ...contractdetail.mutations,
    ...contractfee.mutations,
    ...deliveryorder.mutations,
    ...deliveryorderdetail.mutations,
    ...ladingorder.mutations,
    ...ladingorderdetail.mutations,
    ...receiptinfo.mutations,
    ...receiptinfodetail.mutations,
    ...billpay.mutations
  },
  actions: {
    ...contract.actions,
    ...contractdetail.actions,
    ...contractfee.actions,
    ...deliveryorder.actions,
    ...deliveryorderdetail.actions,
    ...ladingorder.actions,
    ...ladingorderdetail.actions,
    ...receiptinfo.actions,
    ...receiptinfodetail.actions,
    ...billpay.actions
  }
}
