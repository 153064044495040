import { http } from 'security-view-deal'

export default {
  insert (params) {
    return http.post('/contract/ladingorder/insert/selective', params)
  },
  update (params) {
    return http.put('/contract/ladingorder/update/selective/primary', params)
  },
  remove (params) {
    return http.delete('/contract/ladingorder/delete/primary', params)
  },
  findOne (params) {
    return http.get('/contract/ladingorder/vo/find', params)
  },
  selectByPrimary (params) {
    return http.get('/contract/ladingorder/select/primary', params)
  },
  selectPage (params) {
    return http.get('/contract/ladingorder/paged', params)
  },
  findList (params) {
    return http.get('/contract/ladingorder/vo/list', params)
  },
  findPage (params) {
    return http.get('/contract/ladingorder/vo/paged', params)
  },
  batchRemove (params) {
    return http.delete('/contract/ladingorder/batch/delete', params)
  }
}
