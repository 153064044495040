export default {
  setReceiptinfoList (state, payload) {
    state.receiptinfoList = [ ...payload ]
  },
  setReceiptinfo (state, payload) {
    state.receiptinfo = { ...payload }
  },
  setReceiptinfoTotal (state, payload) {
    state.receiptinfoTotal = payload
  }
}
