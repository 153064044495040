<template>
  <div class="me-column me-flex">
    <me-form class="me-grid-column-4" ref="form">
      <me-label width="300px" title="品种名称" prop="scategory">
        <me-input clearable v-model="form.scategory" />
      </me-label>
      <me-label width="300px" title="品名" prop="scommodityname">
        <me-input clearable v-model="form.scommodityname" />
      </me-label>
    </me-form>
    <me-line-h />
    <me-table class="me-flex" field-value="sguid" :columns="columns" :data="list" checkbox multiple ref="table">
      <template #header>
        <div class="me-row me-flex">
          <div class="me-row">
            <me-button @click="dialogAdd" plain type="primary" icon="icon-plus-square">新增</me-button>
            <!-- <me-button @click="handlerBatchRemove" plain type="primary" icon="icon-piliangshanchu">批量删除</me-button> -->
            <!-- <me-button @click="handlerBatchRemove" plain type="primary" icon="icon-piliangshanchu">批量删除</me-button> -->
          </div>
          <div class="me-flex">&nbsp;</div>
          <div class="me-row">
            <me-button @click="handlerLoadContractdetailList" type="primary" icon="icon-search">查 询</me-button>
            <me-button @click="$refs.form.reset" plain type="primary">重 置</me-button>
          </div>
        </div>
      </template>
      <template #action="{data}">
        <!-- <me-icon @click="handlerRemove(data)" title="删除">icon-shanchu-m</me-icon> -->
        <me-icon @click="dialogDetails(data)" title="详情">icon-chakan</me-icon>
        <me-icon @click="dialogEdit(data)" title="编辑">icon-bianji</me-icon>
        <!-- <me-icon @click="dialogCopy(data)" title="复制">icon-fuzhi</me-icon> -->
      </template>
      <template #footer>
        <me-paging :pageSize="form.pageSize" :total="total" class="me-flex" @change-page="handlerPaging" />
      </template>
    </me-table>
    <me-dialog @confirm="handlerAdd" title="新增" v-model="statusAdd">
      <contractdetail-add ref="add" />
    </me-dialog>

    <me-dialog @confirm="handlerUpdate" title="修改" v-model="statusUpdate">
      <contractdetail-update ref="update" />
    </me-dialog>

    <me-dialog title="详情" hidden-footer v-model="statusDetails">
      <contractdetail-details ref="details" />
    </me-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ContractdetailAdd from './Add'
import ContractdetailUpdate from './Update'
import ContractdetailDetails from './Details'

export default {
  components: { ContractdetailAdd, ContractdetailUpdate, ContractdetailDetails },
  data () {
    return {
      statusAdd: false,
      statusUpdate: false,
      statusDetails: false,
      activateRow: null,
      selectedData: [ ],
      columns: [
        { label: '操作', field: 'action', layout: 'center' },
        { label: '品种名称', field: 'scategory' },
        { label: '规格', field: 'specification' },
        { label: '品名', field: 'scommodityname' },
        { label: '重量', field: 'fweight' },
        { label: '数量单位', field: 'sweightunit' },
        { label: '货价', field: 'fprice' },
        { label: '件数', field: 'inumber' },
        { label: '交收重量', field: 'foutweight' },
        { label: '交收单价', field: 'foutprice' },
        { label: '交收总额', field: 'foutamount' }
      ],
      form: {
        scategory: '',
        scommodityname: '',
        pageSize: 15,
        pageNum: 1
      },
      list: [],
      total: 0
    }
  },
  created () {
    this.handlerLoadContractdetailList()
  },
  methods: {
    ...mapActions([
      'selectContractdetailPage', 'removeContractdetail', 'batchRemoveContractdetail'
    ]),
    handlerLoadContractdetailList () {
      this.selectContractdetailPage(this.form).then(({ list, total }) => {
        this.list = [ ...list || [] ]
        this.total = total || 0
      })
    },
    async handlerRemove (row) {
      const key = Reflect.get(row, 'sguid')
      await this.$dialog.confirm('确认要删除当前选择的数据？')
      try {
        await this.removeContractdetail({ key })
        this.handlerLoadContractdetailList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerBatchRemove () {
      const data = this.$refs.table.getCheckedRows()
      if (data.length === 0) {
        return this.$message.warning('请选择要删除的数据')
      }
      const sguidList = data.map(item => item.sguid)
      await this.$dialog.confirm('确认要删除当前选择的数据？')
      try {
        await this.batchRemoveContractdetail({ sguidList })
        this.handlerLoadContractdetailList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerAdd () {
      try {
        await this.$refs.add.save()
        this.statusAdd = false
        this.handlerLoadContractdetailList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerUpdate () {
      try {
        await this.$refs.update.save()
        this.statusUpdate = false
        this.handlerLoadContractdetailList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    dialogEdit (data) {
      this.statusUpdate = true
      this.$refs.update.refreshData(data)
    },
    dialogAdd () {
      this.statusAdd = true
      this.$refs.add.refreshData({})
    },
    dialogCopy (data) {
      this.statusAdd = true
      this.$refs.add.refreshData(data)
    },
    dialogDetails (data) {
      this.statusDetails = true
      this.$refs.details.refreshData(data)
    },
    handlerPaging (data) {
      Object.assign(this.form, data)
      this.handlerLoadContractdetailList()
    }
  }
}
</script>
