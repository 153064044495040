<template>
  <div class="me-column" style="width:100%;">
    <me-panel layout="row" title="基本信息">
      <me-form class="me-grid-column-4" ref="form">
        <me-label width="300px" title="合同号" prop="scontractbuyerno">
          {{form.scontractbuyerno}}
          <!-- <me-input readonly v-model="form.scontractbuyerno" /> -->
        </me-label>
        <me-label width="300px" title="卖方名称" prop="ssellermembername">
          {{form.ssellermembername}}
          <!-- <me-input readonly v-model="form.ssellermembername" /> -->
        </me-label>
        <me-label width="300px" title="买方名称" prop="sbuyermembername">
          <!-- <me-input readonly v-model="form.sbuyermembername" /> -->
          {{form.sbuyermembername}}
        </me-label>
        <me-label width="300px" title="合同金额" prop="fgoodsamount">
          <!-- <me-input readonly v-model="form.fgoodsamount" /> -->
          {{form.fgoodsamount}}
        </me-label>
        <me-label width="300px" title="合同总额" prop="ftotalamount">
          <!-- <me-input readonly v-model="form.ftotalamount" /> -->
          {{form.ftotalamount}}
        </me-label>
        <me-label width="300px" title="总重量" prop="ftotalweight">
          <!-- <me-input readonly v-model="form.ftotalweight" /> -->
          {{form.ftotalweight}}
        </me-label>
        <me-label width="300px" title="发货金额" prop="fdeliverygoodsfund">
          <!-- <me-input readonly v-model="form.fdeliverygoodsfund" /> -->
          {{form.fdeliverygoodsfund}}
        </me-label>
        <me-label width="300px" title="发货金额" prop="fdeliveryfund">
          <!-- <me-input readonly v-model="form.fdeliveryfund" /> -->
          {{form.fdeliveryfund}}
        </me-label>
        <me-label width="300px" title="实际交收总量" prop="fdeliveryweight">
          <!-- <me-input readonly v-model="form.fdeliveryweight" /> -->
          {{form.fdeliveryweight}}
        </me-label>
        <me-label width="300px" title="开票金额" prop="finvoiceamount">
          <!-- <me-input readonly v-model="form.finvoiceamount" /> -->
          {{form.finvoiceamount}}
        </me-label>
        <me-label width="300px" title="签订日期" prop="dcontractdate">
          <!-- <me-input readonly v-model="form.finvoiceamount" /> -->
          {{form.dcontractdate}}
        </me-label>
        <me-label width="300px" title="合同状态" prop="icontractstatus">
          <!-- <me-input readonly v-model="form.finvoiceamount" /> -->
            {{$dictValue('CONTRACT__STATUS', form.icontractstatus + '')}}
        </me-label>
      </me-form>
    </me-panel>
    <me-panel>
    <el-tabs type="border-card" style="width:100%;">
      <el-tab-pane label="商品信息">
        <goods-list class="me-flex" :contract-id="form.sguid"/>
      </el-tab-pane>
      <el-tab-pane label="费用信息">
        <contract-fee class="me-flex" :contract-id="form.sguid"/>
      </el-tab-pane>
      <el-tab-pane label="发货单">
        <invoicef class="me-flex" :contract-id="form.sguid"/>
      </el-tab-pane>
      <el-tab-pane label="提货单">
        <invoicet class="me-flex" :contract-id="form.sguid"/>
      </el-tab-pane>
      <el-tab-pane label="付款单信息">
        <billpayList class="me-flex" :contract-id="form.sguid"/>
      </el-tab-pane>
      <el-tab-pane label="发票信息">
        <invoices class="me-flex" :contract-id="form.sguid"/>
      </el-tab-pane>
      <el-tab-pane label="合同日志">
        <contract-log class="me-flex" :contract-id="form.sguid"/>
      </el-tab-pane>
    </el-tabs>
    </me-panel>
    <!-- <me-panel class="me-flex me-column" layout="row" title="商品信息">
      <goods-list class="me-flex" :contract-id="form.sguid"/>
    </me-panel>
    <me-panel class="me-flex me-column" layout="row" title="费用信息">
      <contract-fee class="me-flex" :contract-id="form.sguid"/>
    </me-panel> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import GoodsList from '../contractdetail/List'
import ContractFee from '../contractfee/List'
import Invoicef from '../Invoicef/List'
import Invoicet from '../Invoicet/List'
import Invoices from '../Invoices/List'
import contractLog from '../contractLog/List'
import billpayList from '../billpay/List'
export default {
  components: { GoodsList, ContractFee, Invoicef, Invoicet, Invoices, contractLog, billpayList },
  data () {
    return {
      form: {
        sguid: null,
        scontractbuyerno: '',
        ssellermembername: '',
        sbuyermembername: '',
        fgoodsamount: '',
        ftotalamount: '',
        ftotalweight: '',
        fdeliverygoodsfund: '',
        fdeliveryfund: '',
        fdeliveryweight: '',
        finvoiceamount: '',
        iinvalidatetype: '',
        iopeninvoicetype: '',
        dcontractdate: '',
        icontractstatus: ''
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectContractByPrimary' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectContractByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.me-flex{
  line-height: 35px;
}
</style>
