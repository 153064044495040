<template>
  <me-table has-index class="me-flex" field-value="sguid" :columns="columns" :data="list" >
    <!-- <template #istatus="{data}"> {{$dictValue('LADING_ORDER__STATUS', data.istatus)}} </template> -->
    <template #ideliverystatus="{data}"> {{$dictValue('LADING_ORDER__PROGRESS', data.ideliverystatus)}} </template>
  </me-table>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    contractId: String
  },
  data () {
    return {
      columns: [
        // { label: '操作', field: 'action', layout: 'center', width: '100px' },
        { label: '提货单编号', field: 'sorderno', layout: 'center' },
        { label: '提货总量', field: 'fweight', layout: 'center' },
        { label: '提货手机', field: 'smobile', layout: 'center' },
        { label: '车牌号', field: 'scarno', layout: 'center' },
        { label: '提货总额', field: 'famount', layout: 'center' },
        { label: '提货进度', field: 'ideliverystatus', layout: 'center' },
        // { label: '状态', field: 'istatus', layout: 'center' },
        { label: '仓库', field: 'swarehouse' }
      ],
      form: {
        scontractid: this.contractId,
        pageSize: 15,
        pageNum: 1
      },
      list: [],
      total: 0
    }
  },
  watch: {
    contractId (value) {
      this.form.scontractid = value
      this.handlerLoadContractfeeList()
    }
  },
  methods: {
    ...mapActions([ 'selectLadingorderPage' ]),
    handlerLoadContractfeeList () {
      this.selectLadingorderPage(this.form).then(({ list, total }) => {
        this.list = [ ...list || [] ]
        this.total = total || 0
      })
    }
  }
}
</script>
