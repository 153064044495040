import api from '@http/contract/Receiptinfo.api'
export default {
  insertReceiptinfo (options, payload) {
    return api.insert(payload)
  },
  updateReceiptinfo (options, payload) {
    return api.update(payload)
  },
  removeReceiptinfo (options, payload) {
    return api.remove(payload)
  },
  batchRemoveReceiptinfo (options, payload) {
    return api.batchRemove(payload)
  },
  selectReceiptinfoByPrimary (options, payload) {
    return api.selectByPrimary(payload)
  },
  findReceiptinfo (options, payload) {
    return api.findOne(payload)
  },
  findReceiptinfoList (options, payload) {
    return api.findList(payload)
  },
  selectReceiptinfoPage (options, payload) {
    return api.selectPage(payload)
  },
  findReceiptinfoPage (options, payload) {
    return api.findPage(payload)
  },
  contractLogs(options, payload) {
    return api.contractLog(payload)
  },
  getReceiptDetails(options, payload) {
    return api.getDetails(payload)
  },
  getInvoice(options, payload) {
    return api.getInvoice(payload)
  },
  selectReceiptList(options, payload) {
    return api.selectList(payload)
  }
}
