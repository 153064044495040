export default {
  billpayList (state) {
    return state.billpayList
  },
  billpay (state) {
    return state.billpay
  },
  billpayTotal (state) {
    return state.billpayTotal
  }
}
