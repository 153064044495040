<template>
  <div class="me-column me-flex">
    <me-form class="me-grid-column-4" ref="form">
      <me-label width="300px" title="合同ID" prop="scontractid">
        <me-input clearable v-model="form.scontractid" />
      </me-label>
      <me-label width="300px" title="提单ID" prop="sorderid">
        <me-input clearable v-model="form.sorderid" />
      </me-label>
    </me-form>
    <me-line-h />
    <me-table class="me-flex" field-value="sguid" :columns="columns" :data="list" checkbox multiple ref="table">
      <template #header>
        <div class="me-row me-flex">
          <div class="me-row">
            <me-button @click="dialogAdd" plain type="primary" icon="icon-plus-square">新增</me-button>
            <!-- <me-button @click="handlerBatchRemove" plain type="primary" icon="icon-piliangshanchu">批量删除</me-button> -->
          </div>
          <div class="me-flex">&nbsp;</div>
          <div class="me-row">
            <me-button @click="handlerLoadDeliveryorderdetailList" type="primary" icon="icon-search">查 询</me-button>
            <me-button @click="$refs.form.reset" plain type="primary">重 置</me-button>
          </div>
        </div>
      </template>
      <template #action="{data}">
        <!-- <me-icon @click="handlerRemove(data)" title="删除">icon-shanchu-m</me-icon> -->
        <me-icon @click="dialogDetails(data)" title="详情">icon-chakan</me-icon>
        <me-icon @click="dialogEdit(data)" title="编辑">icon-bianji</me-icon>
        <!-- <me-icon @click="dialogCopy(data)" title="复制">icon-fuzhi</me-icon> -->
      </template>
      <template #footer>
        <me-paging :pageSize="form.pageSize" :total="total" class="me-flex" @change-page="handlerPaging" />
      </template>
    </me-table>
    <me-dialog @confirm="handlerAdd" title="新增" v-model="statusAdd">
      <deliveryorderdetail-add ref="add" />
    </me-dialog>

    <me-dialog @confirm="handlerUpdate" title="修改" v-model="statusUpdate">
      <deliveryorderdetail-update ref="update" />
    </me-dialog>

    <me-dialog title="详情" hidden-footer v-model="statusDetails">
      <deliveryorderdetail-details ref="details" />
    </me-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DeliveryorderdetailAdd from './Add'
import DeliveryorderdetailUpdate from './Update'
import DeliveryorderdetailDetails from './Details'

export default {
  components: { DeliveryorderdetailAdd, DeliveryorderdetailUpdate, DeliveryorderdetailDetails },
  data () {
    return {
      statusAdd: false,
      statusUpdate: false,
      statusDetails: false,
      activateRow: null,
      selectedData: [ ],
      columns: [
        { label: '操作', field: 'action', layout: 'center' },
        { label: '合同ID', field: 'scontractid' },
        { label: '提单ID', field: 'sorderid' },
        { label: '开单重量', field: 'foderweight' },
        { label: '开单件数', field: 'iorderpackage' },
        { label: '发货重量', field: 'fsendweight' },
        { label: '发货件数', field: 'isendpackage' },
        { label: '是否出库完成', field: 'bisfinish' },
        { label: '出库单价', field: 'fprice' }
      ],
      form: {
        scontractid: '',
        sorderid: '',
        pageSize: 15,
        pageNum: 1
      },
      list: [],
      total: 0
    }
  },
  created () {
    this.handlerLoadDeliveryorderdetailList()
  },
  methods: {
    ...mapActions([
      'selectDeliveryorderdetailPage', 'removeDeliveryorderdetail', 'batchRemoveDeliveryorderdetail'
    ]),
    handlerLoadDeliveryorderdetailList () {
      this.selectDeliveryorderdetailPage(this.form).then(({ list, total }) => {
        this.list = [ ...list || [] ]
        this.total = total || 0
      })
    },
    async handlerRemove (row) {
      const key = Reflect.get(row, 'sguid')
      await this.$dialog.confirm('确认要删除当前选择的数据？')
      try {
        await this.removeDeliveryorderdetail({ key })
        this.handlerLoadDeliveryorderdetailList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerBatchRemove () {
      const data = this.$refs.table.getCheckedRows()
      if (data.length === 0) {
        return this.$message.warning('请选择要删除的数据')
      }
      const sguidList = data.map(item => item.sguid)
      await this.$dialog.confirm('确认要删除当前选择的数据？')
      try {
        await this.batchRemoveDeliveryorderdetail({ sguidList })
        this.handlerLoadDeliveryorderdetailList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerAdd () {
      try {
        await this.$refs.add.save()
        this.statusAdd = false
        this.handlerLoadDeliveryorderdetailList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerUpdate () {
      try {
        await this.$refs.update.save()
        this.statusUpdate = false
        this.handlerLoadDeliveryorderdetailList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    dialogEdit (data) {
      this.statusUpdate = true
      this.$refs.update.refreshData(data)
    },
    dialogAdd () {
      this.statusAdd = true
      this.$refs.add.refreshData({})
    },
    dialogCopy (data) {
      this.statusAdd = true
      this.$refs.add.refreshData(data)
    },
    dialogDetails (data) {
      this.statusDetails = true
      this.$refs.details.refreshData(data)
    },
    handlerPaging (data) {
      Object.assign(this.form, data)
      this.handlerLoadDeliveryorderdetailList()
    }
  }
}
</script>
