import { http } from 'security-view-deal'

export default {
  insert (params) {
    return http.post('/contract/receiptinfodetail/insert/selective', params)
  },
  update (params) {
    return http.put('/contract/receiptinfodetail/update/selective/primary', params)
  },
  remove (params) {
    return http.delete('/contract/receiptinfodetail/delete/primary', params)
  },
  findOne (params) {
    return http.get('/contract/receiptinfodetail/vo/find', params)
  },
  selectByPrimary (params) {
    return http.get('/contract/receiptinfodetail/select/primary', params)
  },
  selectPage (params) {
    return http.get('/contract/receiptinfodetail/paged', params)
  },
  findList (params) {
    return http.get('/contract/receiptinfodetail/vo/list', params)
  },
  findPage (params) {
    return http.get('/contract/receiptinfodetail/vo/paged', params)
  },
  batchRemove (params) {
    return http.delete('/contract/receiptinfodetail/batch/delete', params)
  }
}
