import api from '@http/contract/Deliveryorderdetail.api'
export default {
  insertDeliveryorderdetail (options, payload) {
    return api.insert(payload)
  },
  updateDeliveryorderdetail (options, payload) {
    return api.update(payload)
  },
  removeDeliveryorderdetail (options, payload) {
    return api.remove(payload)
  },
  batchRemoveDeliveryorderdetail (options, payload) {
    return api.batchRemove(payload)
  },
  selectDeliveryorderdetailByPrimary (options, payload) {
    return api.selectByPrimary(payload)
  },
  findDeliveryorderdetail (options, payload) {
    return api.findOne(payload)
  },
  findDeliveryorderdetailList (options, payload) {
    return api.findList(payload)
  },
  selectDeliveryorderdetailPage (options, payload) {
    return api.selectPage(payload)
  },
  findDeliveryorderdetailPage (options, payload) {
    return api.findPage(payload)
  }
}
