export default {
  setDeliveryorderdetailList (state, payload) {
    state.deliveryorderdetailList = [ ...payload ]
  },
  setDeliveryorderdetail (state, payload) {
    state.deliveryorderdetail = { ...payload }
  },
  setDeliveryorderdetailTotal (state, payload) {
    state.deliveryorderdetailTotal = payload
  }
}
