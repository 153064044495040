export default {
  contractfeeList (state) {
    return state.contractfeeList
  },
  contractfee (state) {
    return state.contractfee
  },
  contractfeeTotal (state) {
    return state.contractfeeTotal
  }
}
