import api from '@http/contract/Ladingorderdetail.api'
export default {
  insertLadingorderdetail (options, payload) {
    return api.insert(payload)
  },
  updateLadingorderdetail (options, payload) {
    return api.update(payload)
  },
  removeLadingorderdetail (options, payload) {
    return api.remove(payload)
  },
  batchRemoveLadingorderdetail (options, payload) {
    return api.batchRemove(payload)
  },
  selectLadingorderdetailByPrimary (options, payload) {
    return api.selectByPrimary(payload)
  },
  findLadingorderdetail (options, payload) {
    return api.findOne(payload)
  },
  findLadingorderdetailList (options, payload) {
    return api.findList(payload)
  },
  selectLadingorderdetailPage (options, payload) {
    return api.selectPage(payload)
  },
  findLadingorderdetailPage (options, payload) {
    return api.findPage(payload)
  }
}
