<template>
  <me-table has-index class="me-flex" field-value="sguid" :columns="columns" :data="list" >
  </me-table>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    contractId: String
  },
  data () {
    return {
      columns: [
        { label: '支付方式', field: 'iacctpaytype' },
        { label: '费用业务类型', field: 'ifeetype', layout: 'center' },
        { label: '收费项目', field: 'sfeeitem' },
        { label: '收费金额', field: 'famount', layout: 'center' },
        { label: '支付状态', field: 'ipaystatus', layout: 'center' },
        { label: '交易状态', field: 'itradestatus', layout: 'center' },
        { label: '作废类型', field: 'icanceltype', layout: 'center' },
        { label: '作废原因', field: 'scancelremark' }
      ],
      form: {
        scontractid: this.contractId,
        pageSize: 15,
        pageNum: 1
      },
      list: [],
      total: 0
    }
  },
  watch: {
    contractId (value) {
      this.form.scontractid = value
      this.handlerLoadContractfeeList()
    }
  },
  methods: {
    ...mapActions([ 'selectContractfeePage' ]),
    handlerLoadContractfeeList () {
      this.selectContractfeePage(this.form).then(({ list, total }) => {
        this.list = [ ...list || [] ]
        this.total = total || 0
      })
    }
  }
}
</script>
