<template>
  <me-table has-index class="me-flex" field-value="sguid" :columns="columns" :data="list" >
    <template #iacctpaytype="{data}">
      {{ {0: '在线支付', 1: '线下支付', 2: '拉卡拉支付'}[data.iacctpaytype] || '未知' }}
    </template>
    <template #ipaytype="{data}">
      {{ {10: '合同', 20: '项目结算单', 30: '代采合同', 40: '分期', 1000: '其他'}[data.ipaytype] || '未知' }}
    </template>
    <template #ipaystatus="{data}">
      {{ {10: '待支付', 20: '支付中', 30: '支付完成', 40: '支付失败', 99: '作废'}[data.ipaystatus] || '未知' }}
    </template>
    <template #itradestatus="{data}">
      {{ {0: '未提交', 1: '支付中', 2: '支付完成', 3: '支付失败'}[data.itradestatus] || '未知' }}
    </template>
    <template #isendpoint="{data}">
      {{ {1: '支付方', 2: '收款方', 3: '平台', 4: '系统'}[data.isendpoint] || '未知' }}
    </template>
  </me-table>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    contractId: String
  },
  data () {
    return {
      columns: [
        { label: '支付单号', field: 'spayno' },
        { label: '支付方式', field: 'iacctpaytype' },
        { label: '支付业务类型', field: 'ipaytype' },
        { label: '支付状态', field: 'ipaystatus' },
        { label: '交易状态', field: 'itradestatus' },
        { label: '支付金额', field: 'famount' },
        { label: '支付操作员', field: 'spayuser' },
        { label: '发起方', field: 'isendpoint' }
      ],
      form: {
        scontractid: this.contractId,
        pageSize: 15,
        pageNum: 1
      },
      list: [],
      total: 0
    }
  },
  watch: {
    contractId (value) {
      this.form.scontractid = value
      this.handlerLoadContractfeeList()
    }
  },
  methods: {
    ...mapActions([ 'selectPayList' ]),
    handlerLoadContractfeeList () {
      this.selectPayList({ scontractid: this.form.scontractid }).then((list) => {
        this.list = [ ...list || [] ]
      })
    }
  }
}
</script>
