<template>
  <me-form class="me-grid-column-4" ref="form">
    <me-label width="300px" title="支付方式" prop="iacctpaytype">
      <me-input readonly v-model="form.iacctpaytype" />
    </me-label>
    <me-label width="300px" title="费用业务类型" prop="ifeetype">
      <me-input readonly v-model="form.ifeetype" />
    </me-label>
    <me-label width="300px" title="收费项目" prop="sfeeitem">
      <me-input readonly v-model="form.sfeeitem" />
    </me-label>
    <me-label width="300px" title="补充说明" prop="sfeeremark">
      <me-input readonly v-model="form.sfeeremark" />
    </me-label>
    <me-label width="300px" title="收费金额" prop="famount">
      <me-input readonly v-model="form.famount" />
    </me-label>
    <me-label width="300px" title="支付状态" prop="ipaystatus">
      <me-input readonly v-model="form.ipaystatus" />
    </me-label>
    <me-label width="300px" title="交易状态" prop="itradestatus">
      <me-input readonly v-model="form.itradestatus" />
    </me-label>
    <me-label width="300px" title="发起方" prop="isendpoint">
      <me-input readonly v-model="form.isendpoint" />
    </me-label>
    <me-label width="300px" title="作废类型" prop="icanceltype">
      <me-input readonly v-model="form.icanceltype" />
    </me-label>
    <me-label width="300px" title="作废原因" prop="scancelremark">
      <me-input readonly v-model="form.scancelremark" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        sguid: '',
        iacctpaytype: '',
        ifeetype: '',
        sfeeitem: '',
        sfeeremark: '',
        famount: '',
        ipaystatus: '',
        itradestatus: '',
        isendpoint: '',
        icanceltype: '',
        scancelremark: ''
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectContractfeeByPrimary' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectContractfeeByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
      }
    }
  }
}
</script>
