<template>
  <me-form class="me-grid-column-4" :rules="rules" ref="form">
    <me-label width="300px" title="合同ID" prop="scontractid">
      <me-input clearable v-model="form.scontractid" />
    </me-label>
    <me-label width="300px" title="提单ID" prop="sorderid">
      <me-input clearable v-model="form.sorderid" />
    </me-label>
    <me-label width="300px" title="开单重量" prop="foderweight">
      <me-input clearable v-model="form.foderweight" />
    </me-label>
    <me-label width="300px" title="开单件数" prop="iorderpackage">
      <me-input clearable v-model="form.iorderpackage" />
    </me-label>
    <me-label width="300px" title="发货重量" prop="fsendweight">
      <me-input clearable v-model="form.fsendweight" />
    </me-label>
    <me-label width="300px" title="发货件数" prop="isendpackage">
      <me-input clearable v-model="form.isendpackage" />
    </me-label>
    <me-label width="300px" title="出库单价" prop="fprice">
      <me-input clearable v-model="form.fprice" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      formDefault: {
        sguid: '',
        scontractid: '',
        sorderid: '',
        foderweight: '',
        iorderpackage: '',
        fsendweight: '',
        isendpackage: '',
        fprice: ''
      },
      form: { ...this.formDefault },
      rules: {
        sguid: [
          { required: true, message: '主键不能为空' }
        ],
        scontractid: [
          { required: true, message: '合同ID不能为空' }
        ],
        sorderid: [
          { required: true, message: '提单ID不能为空' }
        ],
        foderweight: [
          { required: true, message: '开单重量不能为空' }
        ],
        iorderpackage: [
          { required: true, message: '开单件数不能为空' }
        ],
        fsendweight: [
          { required: true, message: '发货重量不能为空' }
        ],
        isendpackage: [
          { required: true, message: '发货件数不能为空' }
        ],
        fprice: [
          { required: true, message: '出库单价不能为空' }
        ]
      }
    }
  },
  methods: {
    ...mapActions([ 'selectLadingorderdetailByPrimary', 'insertLadingorderdetail' ]),
    async save () {
      await this.$refs.form.validate()
      await this.insertLadingorderdetail(this.form)
    },
    async refreshData ({ sguid }) {
      this.form = { ...this.formDefault }
      if (this.$tools.notBlank(sguid)) {
        const data = await this.selectLadingorderdetailByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data, sguid: undefined })
      }
    },
    reset () {
      this.$refs.form.reset()
    }
  }
}
</script>
