import api from '@http/contract/Billpay.api'
export default {
  insertBillpay (options, payload) {
    return api.insert(payload)
  },
  updateBillpay (options, payload) {
    return api.update(payload)
  },
  removeBillpay (options, payload) {
    return api.remove(payload)
  },
  batchRemoveBillpay (options, payload) {
    return api.batchRemove(payload)
  },
  selectBillpayByPrimary (options, payload) {
    return api.selectByPrimary(payload)
  },
  findBillpay (options, payload) {
    return api.findOne(payload)
  },
  findBillpayList (options, payload) {
    return api.findList(payload)
  },
  selectBillpayPage (options, payload) {
    return api.selectPage(payload)
  },
  findBillpayPage (options, payload) {
    return api.findPage(payload)
  },
  getBillpayDetails(options, payload) {
    return api.getDetails(payload)
  },
  billpayPage(options, payload) {
    return api.billpayPage(payload)
  },
  selectPayList(options, payload) {
    return api.selectList(payload)
  }
}
