export default {
  setContractList (state, payload) {
    state.contractList = [ ...payload ]
  },
  setContract (state, payload) {
    state.contract = { ...payload }
  },
  setContractTotal (state, payload) {
    state.contractTotal = payload
  }
}
