<template>
  <div>
      <el-collapse class="dialog-collapse" v-model="activeNames">
        <el-form :model="form" ref="detailDialogForm">
          <el-collapse-item class="dialog-info-row" name="1" title="开票详情">
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item label="单据编号：" style="margin: 0;">
                  {{form.sbillno}}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="收票方：" style="margin: 0;">
                  {{form.sbuyermembername || '-'}}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="状态：" style="margin: 0;">
                  {{ (this.billStatusList.find(v => v.value === form.ibillstatus) || {label: '未知'}).label }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item label="开票金额：" prop="finvoiceamount">
                  {{form.finvoiceamount}}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="开票时间：" prop="dinvoicedate">
                  {{form.dinvoicedate}}
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注：">
                  {{form.sremark}}
                </el-form-item>
              </el-col>
            </el-row>
          </el-collapse-item>
          <el-collapse-item class="dialog-info-row" name="2" title="附件" v-if="form.fileList.length > 0">
            <el-table :data="form.fileList" border>
              <el-table-column label="附件">
                <template slot-scope="scope">
                  <el-image v-if="utils.isImage(scope.row.fileUrl)"
                            style="width: 100px; height: 100px"
                            :src="utils.repairFileUrl(scope.row.fileUrl)"
                            :preview-src-list="[utils.repairFileUrl(scope.row.fileUrl)]">
                  </el-image>
                  <p v-else>{{scope.row.fileName}}</p>
                </template>
              </el-table-column>
              <el-table-column label="类型" prop="type"></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-link :href="utils.repairFileUrl(scope.row.fileUrl)" target="_blank" type="primary">下载</el-link>
                </template>
              </el-table-column>
            </el-table>
          </el-collapse-item>
          <el-collapse-item class="dialog-info-row" name="3" title="开票列表">
            <el-table :data="form.details" border class="table-btn-text" style="width: 100%">
              <el-table-column label="合同编号" prop="contract.scontractno" width="130" fixed="left"></el-table-column>
              <el-table-column label="买方名称" prop="contract.sbuyermembername" width="210"></el-table-column>
              <el-table-column label="签订日期" prop="contract.dcontractdate" width="130"></el-table-column>
              <el-table-column label="合同金额" prop="contract.fgoodsamount" width="110"></el-table-column>

              <el-table-column label="已收金额" prop="contract.fsellerreceived" width="110"></el-table-column>
              <el-table-column label="未收金额" width="110">
                <template slot-scope="scope">
                  {{scope.row.contract.ftotalamount - scope.row.contract.fsellerreceived}}
                </template>
              </el-table-column>
              <el-table-column label="已开票金额" prop="contract.finvoiceamount" width="110"></el-table-column>
              <el-table-column label="未开票金额" width="110">
                <template slot-scope="scope">
                  {{scope.row.contract.ftotalamount - scope.row.contract.finvoiceamount | toDecimal}}
                </template>
              </el-table-column>
              <el-table-column label="开票状态" width="110" :formatter="row => {
                  return (this.ticketStatusList.find(v => v.value === row.contract.iticketstatus) || {label: '未知'}).label;
                }"></el-table-column>
              <el-table-column label="开票金额" fixed="right" min-width="180">
                <template slot-scope="scope">
                  <span>{{scope.row.finvoicemoney}}</span>
                </template>
              </el-table-column>
            </el-table>

          </el-collapse-item>
          <br/>
          <el-collapse-item class="dialog-info-row padding" name="4" title="开票信息">
            <el-card class="box-card" style="overflow: hidden">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="发票类型：">
                    <span>
                      {{ (this.typeList.find(v => v.value === form.invoice.itype) || {label: '未知'}).label }}
                    </span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item label="公司名称：" prop="sbuyermembername">
                    <span>{{form.invoice.scompanynmae}}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="税号：" prop="staxno">
                    <span>{{form.invoice.staxno}}</span>
                  </el-form-item>
                </el-col>
              </el-row>

              <template v-if="form.invoice.itype === 0">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-form-item label="电话：" prop="stel">
                      <span>{{form.invoice.stel}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="开户行：" prop="sbank">
                      <span>{{form.invoice.sbank}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="账号：" prop="sbankaccount">
                      <span>{{form.invoice.sbankaccount}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="地址：">
                      <span>{{form.invoice.sprovince+form.invoice.scity+form.invoice.sregion+form.invoice.srecvaddr}}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </el-card>
          </el-collapse-item>
          <el-collapse-item class="dialog-info-row padding" name="5" title="收票信息">
            <el-card class="box-card" style="overflow: hidden">
              <el-row :gutter="10" style="border: none;">
                <el-col :span="8">
                  <el-form-item label="收票人：" style="margin: 0;">
                    <span>{{form.srecvname}}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系电话：" style="margin: 0;">
                    <span>{{form.srecvtel}}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="详细地址：" style="margin: 10px 0 0 0;">
                    <span>{{form.sprovince+form.scity+form.sregion+form.srecvaddr}}</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </el-collapse-item>
        </el-form>

      </el-collapse>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import utils from '@/assets/script/utils'

export default {
  data () {
    return {
      utils: utils,
      activeNames: [ '1', '2', '3', '4', '5' ],
      billStatusList: [
        { label: '作废', value: '10' },
        { label: '草稿', value: '30' },
        { label: '待收票', value: '40' },
        { label: '已收票', value: '60' }
      ],
      typeList: [
        { label: '增值税发票', value: 0 },
        { label: '普通发票', value: 1 }
      ],
      ticketStatusList: [
        { label: '未开票', value: 10 },
        { label: '部分开票', value: 20 },
        { label: '开票完成', value: 100 }
      ],
      form: {
        sguid: '',
        sinvoiceid: '',
        sbillno: '',
        sbuyermembername: '',
        ssellermembername: '',
        ibillstatus: '',
        finvoiceamount: '',
        dinvoicedate: '',
        srecvname: '',
        srecvtel: '',
        sprovince: '',
        scity: '',
        sregion: '',
        srecvaddr: '',
        invoice: {},
        details: [],
        fileList: []
      }
    }
  },
  created () {
    // const sguid = this.$route.query.sguid
    // this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectReceiptinfoByPrimary', 'getReceiptDetails', 'getInvoice' ]),
    async refreshData (data) {
      this.form.invoice = {}
      this.form.details = []
      this.form.fileList = []

      if (data) {
        await this.getReceiptDetails({ sreceinfoid: data.sguid }).then((res) => {
          if (!res) {
            this.$message.error('数据获取失败')
          } else {
            this.$tools.assign(this.form, { ...data })
            this.form.details = res
          }
        })

        if (!this.utils.isEmpty(this.form.sinvoiceid)) {
          // 查询发票抬头详情信息
          await this.getInvoice({
            id: this.form.sinvoiceid
          }).then(res => {
            if (res) {
              this.form.invoice = res
            }
          }).catch(error => {
            console.error('查询发票抬头详情失败:', error)
          })
        }

        if (data.sjson) {
          const sjson = JSON.parse(data.sjson);
          (sjson.buyer || []).forEach(v => {
            this.form.fileList.push({
              fileUrl: v,
              fileName: v.substring(v.lastIndexOf('/') + 1),
              type: '买家'
            })
          });
          (sjson.seller || []).forEach(v => {
            this.form.fileList.push({
              fileUrl: v,
              fileName: v.substring(v.lastIndexOf('/') + 1),
              type: '卖家'
            })
          })
        }
      }
    }
  }
}
</script>
