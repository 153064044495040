export default {
  contractdetailList (state) {
    return state.contractdetailList
  },
  contractdetail (state) {
    return state.contractdetail
  },
  contractdetailTotal (state) {
    return state.contractdetailTotal
  }
}
