import api from '@http/contract/Contract.api'
export default {
  insertContract (options, payload) {
    return api.insert(payload)
  },
  updateContract (options, payload) {
    return api.update(payload)
  },
  removeContract (options, payload) {
    return api.remove(payload)
  },
  batchRemoveContract (options, payload) {
    return api.batchRemove(payload)
  },
  selectContractByPrimary (options, payload) {
    return api.selectByPrimary(payload)
  },
  findContract (options, payload) {
    return api.findOne(payload)
  },
  findContractList (options, payload) {
    return api.findList(payload)
  },
  selectContractPage (options, payload) {
    return api.selectPage(payload)
  },
  findContractPage (options, payload) {
    return api.findPage(payload)
  },
  selectContractPageInfo (options, payload) {
    return api.contractPage(payload)
  },
  exportContractList (options, payload) {
    return api.exportContractList(payload)
  },
  checkDisableAgree(options, payload) {
    return api.checkAgree(payload)
  },
  checkDisableReject(options, payload) {
    return api.checkReject(payload)
  },
  selectFrontLog(options, payload) {
    return api.selectFrontLog(payload)
  }
}
