import api from '@http/contract/Contractfee.api'
export default {
  insertContractfee (options, payload) {
    return api.insert(payload)
  },
  updateContractfee (options, payload) {
    return api.update(payload)
  },
  removeContractfee (options, payload) {
    return api.remove(payload)
  },
  batchRemoveContractfee (options, payload) {
    return api.batchRemove(payload)
  },
  selectContractfeeByPrimary (options, payload) {
    return api.selectByPrimary(payload)
  },
  findContractfee (options, payload) {
    return api.findOne(payload)
  },
  findContractfeeList (options, payload) {
    return api.findList(payload)
  },
  selectContractfeePage (options, payload) {
    return api.selectPage(payload)
  },
  findContractfeePage (options, payload) {
    return api.findPage(payload)
  }
}
