import api from '@http/contract/Contractdetail.api'
export default {
  insertContractdetail (options, payload) {
    return api.insert(payload)
  },
  updateContractdetail (options, payload) {
    return api.update(payload)
  },
  removeContractdetail (options, payload) {
    return api.remove(payload)
  },
  batchRemoveContractdetail (options, payload) {
    return api.batchRemove(payload)
  },
  selectContractdetailByPrimary (options, payload) {
    return api.selectByPrimary(payload)
  },
  findContractdetail (options, payload) {
    return api.findOne(payload)
  },
  findContractdetailList (options, payload) {
    return api.findList(payload)
  },
  selectContractdetailPage (options, payload) {
    return api.selectPage(payload)
  },
  findContractdetailPage (options, payload) {
    return api.findPage(payload)
  }
}
