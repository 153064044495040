import Vue from 'vue'
import Vuex from 'vuex'

import contract from './contract'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    contract
  }
})
