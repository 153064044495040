<template>
  <me-form class="me-grid-column-4" :rules="rules" ref="form">
    <me-label width="300px" title="开票编号" prop="scode">
      <me-input clearable v-model="form.scode" />
    </me-label>
    <me-label width="300px" title="开票量" prop="finvoiceweight">
      <me-input clearable v-model="form.finvoiceweight" />
    </me-label>
    <me-label width="300px" title="进销金额" prop="finvoicemoney">
      <me-input clearable v-model="form.finvoicemoney" />
    </me-label>
    <me-label width="300px" title="开票单价" prop="finvoiceprice">
      <me-input clearable v-model="form.finvoiceprice" />
    </me-label>
    <me-label width="300px" title="备注" prop="sremark">
      <me-input clearable v-model="form.sremark" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        sguid: '',
        scode: '',
        finvoiceweight: '',
        finvoicemoney: '',
        finvoiceprice: '',
        sremark: ''
      },
      rules: {
        sguid: [
          { required: true, message: '主键不能为空' }
        ],
        scode: [
          { required: true, message: '开票编号不能为空' }
        ],
        finvoiceweight: [
          { required: true, message: '开票量不能为空' }
        ],
        finvoicemoney: [
          { required: true, message: '进销金额不能为空' }
        ],
        finvoiceprice: [
          { required: true, message: '开票单价不能为空' }
        ],
        sremark: [
          { required: true, message: '备注不能为空' }
        ]
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectReceiptinfodetailByPrimary', 'updateReceiptinfodetail' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectReceiptinfodetailByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
      }
    },
    async save () {
      await this.$refs.form.validate()
      await this.updateReceiptinfodetail(this.form)
    },
    reset () {
      this.$refs.form.reset()
    }
  }
}
</script>
