export default {
  contractList (state) {
    return state.contractList
  },
  contract (state) {
    return state.contract
  },
  contractTotal (state) {
    return state.contractTotal
  }
}
