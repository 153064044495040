import { ExtendHttp, http } from 'security-view-deal'

export default {
  insert (params) {
    return http.post('/contract/contract/insert/selective', params)
  },
  update (params) {
    return http.put('/contract/contract/update/selective/primary', params)
  },
  remove (params) {
    return http.delete('/contract/contract/delete/primary', params)
  },
  findOne (params) {
    return http.get('/contract/contract/vo/find', params)
  },
  selectByPrimary (params) {
    return http.get('/contract/contract/select/primary', params)
  },
  selectPage (params) {
    return http.get('/contract/contract/paged', params)
  },
  findList (params) {
    return http.get('/contract/contract/vo/list', params)
  },
  findPage (params) {
    return http.get('/contract/contract/vo/paged', params)
  },
  batchRemove (params) {
    return http.delete('/contract/contract/batch/delete', params)
  },
  contractPage (params) {
    return http.post('/contract/contract/contractpaged', params, ExtendHttp.HEADER_JSON)
  },
  exportContractList (params) {
    const blob = { headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' }, responseType: 'blob' }
    return http.post('/contract/contract/exportContractList', params, blob)
  },

  checkAgree (data) {
    return http.post('/contract/contract/disablecheck/agree', data, ExtendHttp.HEADER_JSON)
  },
  checkReject (data) {
    return http.post('/contract/contract/disablecheck/reject', data, ExtendHttp.HEADER_JSON)
  },
  selectFrontLog (data) {
    return http.post('/contract/contract/selectFrontLog', data, ExtendHttp.HEADER_JSON)
  }
}
