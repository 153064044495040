<template>
  <div>
      <el-collapse class="dialog-collapse" v-model="activeNames">
        <el-collapse-item class="dialog-info-row" name="1" title="付款信息">
          <el-row :gutter="10">
            <el-col :span="8">
              <span class="dialog-des-tit">付款单号：</span>
              {{form.spayno}}
            </el-col>
            <el-col :span="8">
              <span class="dialog-des-tit">支付类型：</span>
              {{ acctpaytype[form.iacctpaytype] || '未知' }}
            </el-col>
            <el-col :span="8">
              <span class="dialog-des-tit">支付状态：</span>
              {{ paystatus[form.ipaystatus] || '未知' }}
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <span class="dialog-des-tit">付款金额：</span>
              {{form.famount}}
            </el-col>
            <el-col :span="8">
              <span class="dialog-des-tit">付款方：</span>
              {{form.spaymembername}}
            </el-col>
            <el-col :span="8">
              <span class="dialog-des-tit">收款方：</span>
              {{form.ssellermembername}}
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <span class="dialog-des-tit">备注：</span>
              {{form.sremark}}
            </el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item class="dialog-info-row" name="2" title="付款明细">
          <el-table :data="form.details" border class="m-t-15 table-btn-text" style="width: 100%">
            <el-table-column label="系统合同号" min-width="150px" prop="contract.scontractno"></el-table-column>
            <el-table-column label="收款方" prop="contract.ssellermembername" width="214"></el-table-column>
            <el-table-column label="合同金额" prop="contract.ftotalamount" width="110"></el-table-column>

            <el-table-column label="已付金额" prop="contract.fsellerreceived" width="110"></el-table-column>
            <el-table-column label="未付金额" width="110">
              <template slot-scope="scope">
                {{scope.row.contract.ftotalamount - scope.row.contract.fsellerreceived | toDecimal}}
              </template>
            </el-table-column>
            <el-table-column label="已收票金额" prop="contract.finvoiceamount" width="110"></el-table-column>
            <el-table-column label="未收票金额" width="110">
              <template slot-scope="scope">
                {{scope.row.contract.ftotalamount - scope.row.contract.finvoiceamount}}  {{scope.row.contract.ipaystatus}}
              </template>
            </el-table-column>
            <el-table-column label="付款状态" width="100" :formatter="row => {
                      return (payStatusList.find(v => v.value === row.contract.ipaystatus) || {label: '未知'}).label;
                    }"></el-table-column>
            <el-table-column label="付款金额" width="150" prop="famount"></el-table-column>
          </el-table>
        </el-collapse-item>
        <br/>

        <el-collapse-item class="dialog-info-row" name="3" title="附件" v-if="form.fileList.length > 0">
          <el-table :data="form.fileList" border>
            <el-table-column label="附件">
              <template slot-scope="scope">
                <el-image v-if="utils.isImage(scope.row.fileUrl)"
                  style="width: 100px; height: 100px"
                  :src="utils.repairFileUrl(scope.row.fileUrl)"
                  :preview-src-list="[utils.repairFileUrl(scope.row.fileUrl)]">
                </el-image>
                <p v-else>{{scope.row.fileName}}</p>
              </template>
            </el-table-column>
            <el-table-column label="类型" prop="type"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-link :href="utils.repairFileUrl(scope.row.fileUrl)" target="_blank" type="primary">下载</el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
import utils from '@/assets/script/utils'

export default {
  data() {
    return {
      utils: utils,
      activeNames: [ '1', '2', '3' ],
      payStatusList: [
        { label: '未付款', value: 10 },
        { label: '部分付款', value: 20 },
        { label: '付款完成', value: 100 }
      ],
      paystatus: { 10: '待支付', 20: '支付中', 30: '支付完成', 40: '支付失败', 99: '作废' },
      acctpaytype: { 0: '平安银行', 1: '线下支付' },
      form: {
        spayno: '',
        iacctpaytype: '',
        ipaystatus: '',
        itradestatus: '',
        famount: '',
        icanceltype: '',
        scancelremark: '',
        spaybizparam: '',
        ssellermembername: '',
        spaymembername: '',
        details: [],
        fileList: []
      }
    }
  },
  // created () {
  //   const sguid = this.$route.query.sguid
  //   this.refreshData({ sguid })
  // },
  methods: {
    ...mapActions([ 'selectBillpayByPrimary', 'getBillpayDetails' ]),
    async refreshData(data) {
      this.form.fileList = []
      this.form.details = []
      if (data) {
        await this.getBillpayDetails({ sbillpayid: data.sguid }).then((res) => {
          if (!res) {
            this.$message.error('数据获取失败')
          } else {
            this.$tools.assign(this.form, { ...data })
            this.form.details = res
          }
        })
        if (data.sjson) {
          const sjson = JSON.parse(data.sjson);
          (sjson.buyer || []).forEach(v => {
            this.form.fileList.push({
              fileUrl: v,
              fileName: v.substring(v.lastIndexOf('/') + 1),
              type: '买家'
            })
          });
          (sjson.seller || []).forEach(v => {
            this.form.fileList.push({
              fileUrl: v,
              fileName: v.substring(v.lastIndexOf('/') + 1),
              type: '卖家'
            })
          })
        }
      }
    }
  }
}
</script>
