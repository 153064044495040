<template>
  <div class="contractLog">
    <el-timeline :reverse="reverse" field-value="sguid" style="margin-left:20px;margin-top:20px">
      <el-timeline-item
        v-for="(item, index) in list"
        :key="index"
        :color="item.color"
        >
        <p>{{ item.scontent }}</p>
        <p style="font-size:12px;color:#9195A3;margin-top:5px">
          <span v-if="item.ilogtype == 0">系统 </span>
          <span v-if="item.ilogtype == 1">买家 </span>
          <span v-if="item.ilogtype == 2">卖家 </span>
          {{ item.dopertime }}</p>
        <!-- <p>操作人：{{ item.soperator }}</p> -->
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    contractId: String
  },
  components: {},
  data () {
    return {
      form: {
        scontractid: this.contractId,
        pageSize: 15,
        pageNum: 1
      },
      reverse: true,
      list: [],
      formLabelWidth: '100px'
    }
  },
  watch: {
    contractId (value) {
      console.log(value)
      this.form.scontractid = value
      this.queryAllList()
    }
  },
  computed: {},
  methods: {
    ...mapActions([ 'contractLogs' ]),
    // 查询所有收/付款列表
    queryAllList () {
      this.contractLogs(this.form).then(data => {
        if (data.length > 0) {
          data[0].color = '#0bbd87'
          this.list = data
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
