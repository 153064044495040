import { http } from 'security-view-deal'

export default {
  insert (params) {
    return http.post('/contract/deliveryorderdetail/insert/selective', params)
  },
  update (params) {
    return http.put('/contract/deliveryorderdetail/update/selective/primary', params)
  },
  remove (params) {
    return http.delete('/contract/deliveryorderdetail/delete/primary', params)
  },
  findOne (params) {
    return http.get('/contract/deliveryorderdetail/vo/find', params)
  },
  selectByPrimary (params) {
    return http.get('/contract/deliveryorderdetail/select/primary', params)
  },
  selectPage (params) {
    return http.get('/contract/deliveryorderdetail/paged', params)
  },
  findList (params) {
    return http.get('/contract/deliveryorderdetail/vo/list', params)
  },
  findPage (params) {
    return http.get('/contract/deliveryorderdetail/vo/paged', params)
  },
  batchRemove (params) {
    return http.delete('/contract/deliveryorderdetail/batch/delete', params)
  }
}
