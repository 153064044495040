<template>
  <me-table has-index class="me-flex" field-value="sguid" :columns="columns" :data="list">
  </me-table>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    contractId: String
  },
  data () {
    return {
      columns: [
        // { label: '操作', field: 'action', layout: 'center' },
        { label: '品种名称', field: 'scategory' },
        { label: '规格', field: 'specification' },
        { label: '品名', field: 'scommodityname' },
        { label: '重量', field: 'fweight', layout: 'center' },
        { label: '数量单位', field: 'sweightunit', layout: 'center' },
        { label: '货价', field: 'fprice', layout: 'center' },
        { label: '件数', field: 'inumber', layout: 'center' },
        { label: '交收重量', field: 'foutweight', layout: 'center' },
        { label: '交收单价', field: 'foutprice', layout: 'center' },
        { label: '交收总额', field: 'foutamount', layout: 'center' }
      ],
      form: {
        scontractid: this.contractId,
        pageSize: 15,
        pageNum: 1
      },
      list: [],
      total: 0
    }
  },
  watch: {
    contractId (value) {
      this.form.scontractid = value
      this.handlerLoadContractdetailList()
    }
  },
  methods: {
    ...mapActions([ 'selectContractdetailPage' ]),
    handlerLoadContractdetailList () {
      this.selectContractdetailPage(this.form).then(({ list, total }) => {
        this.list = [ ...list || [] ]
        this.total = total || 0
      })
    }
  }
}
</script>
