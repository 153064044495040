<template>
  <me-table has-index class="me-flex" field-value="sguid" :columns="columns" :data="list" >
      <template #itype="{data}">{{$dictValue('INVOICE__TYPE', data.itype)}}</template>
      <template #ibillstatus="{data}">{{$dictValue('INVOICE__STATUS', data.ibillstatus)}}</template>
  </me-table>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    contractId: String
  },
  data () {
    return {
      columns: [
        // { label: '操作', field: 'action', layout: 'center', width: '100px' },
        { label: '单据编号', field: 'sbillno' },
        { label: '收票会员', field: 'sbuyermembername' },
        { label: '开票会员', field: 'ssellermembername' },
        { label: '开票类型', field: 'itype', layout: 'center' },
        { label: '状态', field: 'ibillstatus', layout: 'center' },
        // { label: '单据类型', field: 'ibilltype', layout: 'center' },
        { label: '开票金额', field: 'finvoiceamount', layout: 'center' },
        { label: '开票时间', field: 'dinvoicedate', layout: 'center' }
      ],
      form: {
        scontractid: this.contractId,
        pageSize: 15,
        pageNum: 1
      },
      list: [],
      total: 0
    }
  },
  watch: {
    contractId (value) {
      this.form.scontractid = value
      this.handlerLoadContractfeeList()
    }
  },
  methods: {
    ...mapActions([ 'selectReceiptinfoPage', 'selectReceiptList' ]),
    handlerLoadContractfeeList () {
      this.selectReceiptList({ scontractid: this.form.scontractid }).then((list) => {
        this.list = [ ...list || [] ]
      })
    }
  }
}
</script>
