import { http, ExtendHttp } from 'security-view-deal'

export default {
  insert (params) {
    return http.post('/contract/receiptinfo/insert/selective', params)
  },
  update (params) {
    return http.put('/contract/receiptinfo/update/selective/primary', params)
  },
  remove (params) {
    return http.delete('/contract/receiptinfo/delete/primary', params)
  },
  findOne (params) {
    return http.get('/contract/receiptinfo/vo/find', params)
  },
  selectByPrimary (params) {
    return http.get('/contract/receiptinfo/select/primary', params)
  },
  selectPage (params) {
    return http.get('/contract/receiptinfo/vo/paged', params)
  },
  findList (params) {
    return http.get('/contract/receiptinfo/vo/list', params)
  },
  findPage (params) {
    return http.get('/contract/receiptinfo/vo/paged', params)
  },
  batchRemove (params) {
    return http.delete('/contract/receiptinfo/batch/delete', params)
  },
  contractLog (params) {
    return http.post('/contract/logtracking/list', params, ExtendHttp.HEADER_JSON)
  },
  getDetails (params) {
    return http.post('/contract/receiptinfo/getDetails', params, ExtendHttp.HEADER_JSON)
  },
  getInvoice (params) {
    return http.get('/member/invoiceinfo/find', params)
  },
  selectList (params) {
    return http.post('/contract/receiptinfo/getReceiptsWithDetail', params, ExtendHttp.HEADER_JSON)
  }
}
