<template>
  <div class="me-column me-flex">
    <me-form class="me-row" ref="form">
      <me-label width="300px" title="站点来源" prop="substation">
        <me-combo-select clearable :data="$dictList('SUB_LAIYUAN')" readonly v-model="form.substation" placeholder="请下拉选择"/>
      </me-label>
      <!-- <me-label width="300px" title="合同号" prop="scontractbuyerno">
        <me-input clearable v-model="form.scontractbuyerno" />
      </me-label> -->
      <me-label width="300px" title="卖家名称" prop="ssellermembername">
        <me-input clearable v-model="form.ssellermembername" />
      </me-label>
      <me-label width="300px" title="买家名称" prop="sbuyermembername">
        <me-input clearable v-model="form.sbuyermembername" />
      </me-label>
      <me-label width="300px" title="订单状态" prop="icontractstatus">
        <me-combo-select :data="$dictList('CONTRACT__STATUS')" clearable readonly v-model="form.icontractstatus" />
      </me-label>
    </me-form>
    <!-- <me-form class="me-row" ref="form">
      <me-label width="300px" title="创建时间" prop="dadddate">
        <el-date-picker
          style="height:30px;"
          clearable
          v-model="form.timeRange"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </me-label>
    </me-form> -->
    <me-line-h />
    <me-table has-index class="me-flex" field-value="sguid" :columns="columns" :data="lists" ref="table">
      <template #header>
        <div class="me-row me-flex">
          <div class="me-flex">&nbsp;</div>
          <div class="me-row">
            <!-- <me-button @click="toExport()" size="small" type="primary">导出Excel</me-button> -->
            <me-button @click="handlerLoadContractList" type="primary" icon="icon-search">查 询</me-button>
            <me-button @click="reset" plain type="primary">重 置</me-button>
          </div>
        </div>
      </template>
      <template #substation="{data}">
        {{$dictValue('SUB_LAIYUAN', data.substation + '')}}
      </template>
      <template #icontractstatus="{data}">
        {{$dictValue('CONTRACT__STATUS', data.icontractstatus + '')}}
      </template>
      <template #action="{data}">
        <!-- <me-icon @click="handlerRemove(data)" title="删除">icon-shanchu-m</me-icon> -->
        <me-icon @click="openTabDetails(data)" title="详情">icon-chakan</me-icon>
        <!-- <me-icon @click="dialogEdit(data)" title="编辑">icon-bianji</me-icon> -->
        <!-- <me-icon @click="dialogCopy(data)" title="复制">icon-fuzhi</me-icon> -->
      </template>
      <template #footer>
        <me-paging :pageSize="form.pageSize" :total="total" class="me-flex" @change-page="handlerPaging" />
      </template>
      <template #sdelivertype="{data}">
        {{ {2: '卖方仓库', 1: '平台仓库'}[data.sdelivertype] || '未知' }}
      </template>
    </me-table>
    <!-- <me-dialog @confirm="handlerAdd" title="新增" v-model="statusAdd">
      <contract-add ref="add" />
    </me-dialog>

    <me-dialog @confirm="handlerUpdate" title="修改" v-model="statusUpdate">
      <contract-update ref="update" />
    </me-dialog>

    <me-dialog title="详情" hidden-footer v-model="statusDetails">
      <contract-details ref="details" />
    </me-dialog> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import ContractAdd from './Add'
// import ContractUpdate from './Update'
// import ContractDetails from './Details'

export default {
  // components: { ContractAdd, ContractUpdate, ContractDetails },
  data () {
    return {
      statusAdd: false,
      statusUpdate: false,
      statusDetails: false,
      activateRow: null,
      selectedData: [ ],
      columns: [
        { label: '操作', field: 'action', layout: 'center', width: '100px' },
        { label: '来源', field: 'substation', layout: 'center' },
        // { label: '合同号', field: 'scontractbuyerno' },
        { label: '仓库类型', field: 'sdelivertype', layout: 'center' },
        { label: '卖家名称', field: 'ssellermembername' },
        { label: '买家名称', field: 'sbuyermembername' },
        { label: '合同总额', field: 'ftotalamount', layout: 'center' },
        // { label: '开票金额', field: 'finvoiceamount', layout: 'center' },
        { label: '合同重量', field: 'ftotalweight', layout: 'center' },
        { label: '交货日期', field: 'ddeliverydate', layout: 'center' },
        { label: '确认时间', field: 'dsellerconfirmdate', layout: 'center' },
        { label: '创建时间', field: 'dadddate', layout: 'center' },
        // { label: '已付款金额', field: 'fbuyerpaid', layout: 'center' },
        // { label: '未付款金额', field: 'syMoney', layout: 'center' },
        // { label: '未开票金额', field: 'fnoinvoiceamount', layout: 'center' },
        // { label: '未发货重量', field: 'fnodeliveryweight', layout: 'center' },
        { label: '订单状态', field: 'icontractstatus', layout: 'center', width: '80px' }
      ],
      form: {
        scontractbuyerno: '',
        ssellermembername: '',
        sbuyermembername: '',
        icontractstatus: '',
        dadddate: '',
        imodeltype: 20,
        pageSize: 15,
        pageNum: 1
        // timeRange: ''
      },
      list: [],
      lists: [],
      total: 0
    }
  },
  created () {
    this.handlerLoadContractList()
  },
  methods: {
    ...mapActions([
      'selectContractPage', 'removeContract', 'batchRemoveContract', 'selectContractPageInfo', 'exportContractList'
    ]),
    toExport() {
      this.exportContractList(this.form).then(res => {
        if (res) {
          const url = window.URL.createObjectURL(res)
          const elink = document.createElement('a')
          elink.download = 'contract123.xls'
          elink.href = url
          elink.style.display = 'none'
          document.body.appendChild(elink)
          elink.click()
          document.body.removeChild(elink)
        }
      })
    },
    handlerLoadContractList () {
      this.lists = []
      this.selectContractPageInfo(this.form).then(({ list, total }) => {
        this.list = [ ...list || [] ]
        this.list.forEach(item => {
          if (item.icontractstatus === 10 || item.icontractstatus === 20) {
            this.lists.push(item)
            // item.syMoney = item.ftotalamount - item.fbuyerpaid
            // // item.fnodeliveryfund = item.ftotalamount - (item.fdeliveryfund || 0)
            // item.fnoinvoiceamount = item.ftotalamount - (item.finvoiceamount || 0)
            // item.fnodeliveryweight = item.ftotalweight - (item.fdeliveryweight || 0)
          }
        })
        this.lists.forEach(item => {
          item.syMoney = item.ftotalamount - item.fbuyerpaid
          // item.fnodeliveryfund = item.ftotalamount - (item.fdeliveryfund || 0)
          item.fnoinvoiceamount = item.ftotalamount - (item.finvoiceamount || 0)
          item.fnodeliveryweight = item.ftotalweight - (item.fdeliveryweight || 0)
        })
        this.total = total || 0
      })
    },
    async handlerRemove (row) {
      const key = Reflect.get(row, 'sguid')
      await this.$dialog.confirm('确认要删除当前选择的数据？')
      try {
        await this.removeContract({ key })
        this.handlerLoadContractList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerBatchRemove () {
      const data = this.$refs.table.getCheckedRows()
      if (data.length === 0) {
        return this.$message.warning('请选择要删除的数据')
      }
      const sguidList = data.map(item => item.sguid)
      await this.$dialog.confirm('确认要删除当前选择的数据？')
      try {
        await this.batchRemoveContract({ sguidList })
        this.handlerLoadContractList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerAdd () {
      try {
        await this.$refs.add.save()
        this.statusAdd = false
        this.handlerLoadContractList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerUpdate () {
      try {
        await this.$refs.update.save()
        this.statusUpdate = false
        this.handlerLoadContractList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    dialogEdit (data) {
      this.statusUpdate = true
      this.$refs.update.refreshData(data)
    },
    dialogAdd () {
      this.statusAdd = true
      this.$refs.add.refreshData({})
    },
    dialogCopy (data) {
      this.statusAdd = true
      this.$refs.add.refreshData(data)
    },
    dialogDetails (data) {
      this.statusDetails = true
      this.$refs.details.refreshData(data)
    },
    openTabDetails ({ sguid }) {
      window.top.postMessage({
        action: 'openTabPane',
        systemCode: 'PORTAL',
        origin: location.origin,
        title: '合同详情',
        url: `/contract/contract/details?sguid=${sguid}`
      }, '*')
    },
    handlerPaging (data) {
      Object.assign(this.form, data)
      this.handlerLoadContractList()
    },
    reset () {
      this.$refs.form.reset()
      this.handlerLoadContractList()
    }
  }
}
</script>
<style>
.el-date-editor .el-range__icon {
    font-size: 14px;
    margin-left: -5px;
    color: #C0C4CC;
    float: left;
    line-height: 25px;
}
.el-date-editor .el-range-separator {
    padding: 0 5px;
    line-height: 25px;
    width: 5%;
    color: #303133;
}
</style>
