export default {
  setLadingorderdetailList (state, payload) {
    state.ladingorderdetailList = [ ...payload ]
  },
  setLadingorderdetail (state, payload) {
    state.ladingorderdetail = { ...payload }
  },
  setLadingorderdetailTotal (state, payload) {
    state.ladingorderdetailTotal = payload
  }
}
