import api from '@http/contract/Ladingorder.api'
export default {
  insertLadingorder (options, payload) {
    return api.insert(payload)
  },
  updateLadingorder (options, payload) {
    return api.update(payload)
  },
  removeLadingorder (options, payload) {
    return api.remove(payload)
  },
  batchRemoveLadingorder (options, payload) {
    return api.batchRemove(payload)
  },
  selectLadingorderByPrimary (options, payload) {
    return api.selectByPrimary(payload)
  },
  findLadingorder (options, payload) {
    return api.findOne(payload)
  },
  findLadingorderList (options, payload) {
    return api.findList(payload)
  },
  selectLadingorderPage (options, payload) {
    return api.selectPage(payload)
  },
  findLadingorderPage (options, payload) {
    return api.findPage(payload)
  }
}
