export default {
  receiptinfoList (state) {
    return state.receiptinfoList
  },
  receiptinfo (state) {
    return state.receiptinfo
  },
  receiptinfoTotal (state) {
    return state.receiptinfoTotal
  }
}
