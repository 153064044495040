export default {
  receiptinfodetailList (state) {
    return state.receiptinfodetailList
  },
  receiptinfodetail (state) {
    return state.receiptinfodetail
  },
  receiptinfodetailTotal (state) {
    return state.receiptinfodetailTotal
  }
}
