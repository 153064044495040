<template>
  <div class="me-column me-flex">
    <me-form class="me-grid-column-4" ref="form">
      <me-label width="300px" title="站点来源" prop="substation">
        <me-combo-select clearable :data="$dictList('SUB_LAIYUAN')" readonly v-model="form.substation" placeholder="请下拉选择"/>
      </me-label>
      <me-label width="300px" title="支付单号" prop="spayno">
        <me-input clearable v-model="form.spayno" />
      </me-label>
      <me-label width="300px" title="支付状态" prop="ipaystatus">
        <el-select v-model="form.ipaystatus">
          <el-option label="支付完成" value="30"></el-option>
          <el-option label="作废" value="99"></el-option>
          <el-option label="待支付" value="10"></el-option>
          <el-option label="支付中" value="20"></el-option>
        </el-select>
        <!-- <me-input clearable v-model="form.ipaystatus" /> -->
      </me-label>
      <me-label width="300px" title="交易状态" prop="itradestatus">
        <el-select v-model="form.itradestatus" style="height:30px">
          <el-option label="支付失败" value="3"></el-option>
          <el-option label="支付完成" value="2"></el-option>
          <el-option label="支付中" value="1"></el-option>
        </el-select>
        <!-- <me-input clearable v-model="form.itradestatus" /> -->
      </me-label>
    </me-form>
    <me-line-h />
    <me-table class="me-flex" field-value="sguid" :columns="columns" :data="list" checkbox multiple ref="table">
      <template #header>
        <div class="me-row me-flex">
          <div class="me-row">
            <!-- <me-button @click="dialogAdd" plain type="primary" icon="icon-plus-square">新增</me-button> -->
            <!-- <me-button @click="handlerBatchRemove" plain type="primary" icon="icon-piliangshanchu">批量删除</me-button> -->
          </div>
          <div class="me-flex">&nbsp;</div>
          <div class="me-row">
            <me-button @click="handlerLoadBillpayList" type="primary" icon="icon-search">查 询</me-button>
            <me-button @click="$refs.form.reset" plain type="primary">重 置</me-button>
          </div>
        </div>
      </template>
      <template #substation="{data}">
        {{$dictValue('SUB_LAIYUAN', data.substation + '')}}
      </template>
      <template #action="{data}">
        <!-- <me-icon @click="handlerRemove(data)" title="删除">icon-shanchu-m</me-icon> -->
        <me-icon @click="dialogDetails(data)" title="详情">icon-chakan</me-icon>
        <!-- <me-icon @click="dialogEdit(data)" title="编辑">icon-bianji</me-icon> -->
        <!-- <me-icon @click="dialogCopy(data)" title="复制">icon-fuzhi</me-icon> -->
      </template>
      <template #iacctpaytype="{data}">
        {{ {0: '在线支付', 1: '线下支付', 2: '拉卡拉支付'}[data.iacctpaytype] || '未知' }}
      </template>
      <template #ipaytype="{data}">
        {{ {10: '合同', 20: '项目结算单', 30: '代采合同', 40: '分期', 1000: '其他'}[data.ipaytype] || '未知' }}
      </template>
      <template #ipaystatus="{data}">
        {{ {10: '待支付', 20: '支付中', 30: '支付完成', 40: '支付失败', 99: '作废'}[data.ipaystatus] || '未知' }}
      </template>
      <template #itradestatus="{data}">
        {{ {0: '未提交', 1: '支付中', 2: '支付完成', 3: '支付失败'}[data.itradestatus] || '未知' }}
      </template>
      <template #isendpoint="{data}">
        {{ {1: '支付方', 2: '收款方', 3: '平台', 4: '系统'}[data.isendpoint] || '未知' }}
      </template>
      <template #footer>
        <me-paging :pageSize="form.pageSize" :total="total" class="me-flex" @change-page="handlerPaging" />
      </template>
    </me-table>
    <me-dialog @confirm="handlerAdd" title="新增" v-model="statusAdd">
      <billpay-add ref="add" />
    </me-dialog>

    <me-dialog @confirm="handlerUpdate" title="修改" v-model="statusUpdate">
      <billpay-update ref="update" />
    </me-dialog>

    <me-dialog title="详情" hidden-footer v-model="statusDetails">
      <billpay-details ref="details" />
    </me-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BillpayAdd from './Add'
import BillpayUpdate from './Update'
import BillpayDetails from './Details'

export default {
  components: { BillpayAdd, BillpayUpdate, BillpayDetails },
  data () {
    return {
      statusAdd: false,
      statusUpdate: false,
      statusDetails: false,
      activateRow: null,
      selectedData: [ ],
      columns: [
        { label: '操作', field: 'action', layout: 'center' },
        { label: '来源', field: 'substation', layout: 'center' },
        { label: '支付单号', field: 'spayno' },
        { label: '支付方式', field: 'iacctpaytype' },
        { label: '支付业务类型', field: 'ipaytype' },
        { label: '支付状态', field: 'ipaystatus' },
        { label: '交易状态', field: 'itradestatus' },
        { label: '支付金额', field: 'famount' },
        { label: '支付操作员', field: 'spayuser' },
        { label: '发起方', field: 'isendpoint' }
      ],
      form: {
        pageSize: 15,
        pageNum: 1
      },
      list: [],
      total: 0
    }
  },
  created () {
    this.handlerLoadBillpayList()
  },
  methods: {
    ...mapActions([
      'selectBillpayPage', 'removeBillpay', 'batchRemoveBillpay'
    ]),
    handlerLoadBillpayList () {
      this.selectBillpayPage(this.form).then(({ list, total }) => {
        this.list = [ ...list || [] ]
        this.total = total || 0
      })
    },
    async handlerRemove (row) {
      const key = Reflect.get(row, 'sguid')
      await this.$dialog.confirm('确认要删除当前选择的数据？')
      try {
        await this.removeBillpay({ key })
        this.handlerLoadBillpayList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerBatchRemove () {
      const data = this.$refs.table.getCheckedRows()
      if (data.length === 0) {
        return this.$message.warning('请选择要删除的数据')
      }
      const sguidList = data.map(item => item.sguid)
      await this.$dialog.confirm('确认要删除当前选择的数据？')
      try {
        await this.batchRemoveBillpay({ sguidList })
        this.handlerLoadBillpayList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerAdd () {
      try {
        await this.$refs.add.save()
        this.statusAdd = false
        this.handlerLoadBillpayList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    async handlerUpdate () {
      try {
        await this.$refs.update.save()
        this.statusUpdate = false
        this.handlerLoadBillpayList()
      } catch (error) {
        return this.$message.warning(this.$tools.message(error))
      }
    },
    dialogEdit (data) {
      this.statusUpdate = true
      this.$refs.update.refreshData(data)
    },
    dialogAdd () {
      this.statusAdd = true
      this.$refs.add.refreshData({})
    },
    dialogCopy (data) {
      this.statusAdd = true
      this.$refs.add.refreshData(data)
    },
    dialogDetails (data) {
      this.statusDetails = true
      this.$refs.details.refreshData(data)
    },
    handlerPaging (data) {
      Object.assign(this.form, data)
      this.handlerLoadBillpayList()
    }
  }
}
</script>
<style>
.el-input__inner{
  height: 30px;
}
</style>
