<template>
  <div class="me-column me-flex">
    <me-form class="me-row" ref="form">
      <me-label width="300px" title="站点来源" prop="substation">
        <me-combo-select clearable :data="$dictList('SUB_LAIYUAN')" readonly v-model="form.substation" placeholder="请下拉选择"/>
      </me-label>
      <me-label width="300px" title="发货单编号" prop="sorderno">
        <me-input clearable v-model="form.sorderno" />
      </me-label>
      <me-label width="300px" title="物流公司" prop="slogistics">
        <me-input clearable v-model="form.slogistics" />
      </me-label>
      <me-label width="300px" title="物流单号" prop="swaybillno">
        <me-input clearable v-model="form.swaybillno" />
      </me-label>
    </me-form>
    <me-form class="me-row" ref="form">
      <me-label width="300px" title="发货状态" prop="istatus">
        <me-combo-select :data="$dictList('DELIVER_ORDER__STATUS')" clearable readonly v-model="form.istatus" />
      </me-label>
      <me-label width="300px" title="发货进度" prop="ideliverystatus">
        <me-combo-select :data="$dictList('DELIVER_ORDER__PROGRESS')" clearable readonly v-model="form.ideliverystatus" />
      </me-label>
    </me-form>
    <me-line-h />
    <me-table class="me-flex" field-value="sguid" :columns="columns" :data="list" has-index ref="table">
      <template #header>
        <div class="me-row me-flex">
          <div class="me-row">
            <!-- <me-button @click="dialogAdd" plain type="primary" icon="icon-plus-square">新增</me-button> -->
            <!-- <me-button @click="handlerBatchRemove" plain type="primary" icon="icon-piliangshanchu">批量删除</me-button> -->
          </div>
          <div class="me-flex">&nbsp;</div>
          <div class="me-row">
            <me-button @click="toExport()" size="small" type="primary">导出Excel</me-button>
            <me-button @click="handlerLoadDeliveryorderList" type="primary" icon="icon-search">查 询</me-button>
            <me-button @click="$refs.form.reset" plain type="primary">重 置</me-button>
          </div>
        </div>
      </template>
      <template #substation="{data}">
        {{$dictValue('SUB_LAIYUAN', data.substation + '')}}
      </template>
      <template #istatus="{data}"> {{$dictValue('DELIVER_ORDER__STATUS', data.istatus)}} </template>
      <template #ideliverystatus="{data}"> {{$dictValue('DELIVER_ORDER__PROGRESS', data.ideliverystatus)}} </template>
      <template #action="{data}">
        <!-- <me-icon @click="handlerRemove(data)" title="删除">icon-shanchu-m</me-icon> -->
        <me-icon @click="dialogDetails(data)" title="详情">icon-chakan</me-icon>
        <!-- <me-icon @click="dialogEdit(data)" title="编辑">icon-bianji</me-icon> -->
        <!-- <me-icon @click="dialogCopy(data)" title="复制">icon-fuzhi</me-icon> -->
      </template>
      <template #footer>
        <me-paging :pageSize="form.pageSize" :total="total" class="me-flex" @change-page="handlerPaging" />
      </template>
    </me-table>
    <!-- <me-dialog @confirm="handlerAdd" title="新增" v-model="statusAdd">
      <deliveryorder-add ref="add" />
    </me-dialog>

    <me-dialog @confirm="handlerUpdate" title="修改" v-model="statusUpdate">
      <deliveryorder-update ref="update" />
    </me-dialog> -->

    <me-dialog title="详情" hidden-footer v-model="statusDetails">
      <deliveryorder-details ref="details" />
    </me-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import DeliveryorderAdd from './Add'
// import DeliveryorderUpdate from './Update'
import DeliveryorderDetails from './Details'
import { http } from 'security-view-deal'

export default {
  components: { DeliveryorderDetails },
  data () {
    return {
      statusDetails: false,
      columns: [
        { label: '操作', field: 'action', layout: 'center', width: '100px' },
        { label: '来源', field: 'substation', layout: 'center' },
        { label: '发货单编号', field: 'sorderno', layout: 'center' },
        { label: '合同编号', field: 'scontractno', layout: 'center' },
        { label: '卖方会员', field: 'ssellermember', layout: 'center' },
        { label: '买方会员', field: 'sbuyermember', layout: 'center' },
        { label: '发货量', field: 'fweight', layout: 'center' },
        { label: '发货总额', field: 'famount', layout: 'center' },
        { label: '发货进度', field: 'ideliverystatus', layout: 'center' },
        { label: '物流公司', field: 'slogistics' },
        { label: '运单（物流单）', field: 'swaybillno', layout: 'center' },
        { label: '状态', field: 'istatus', layout: 'center' }
      ],
      form: {
        sorderno: '',
        slogistics: '',
        swaybillno: '',
        ideliverystatus: '',
        istatus: '',
        pageSize: 15,
        pageNum: 1
      },
      list: [],
      total: 0
    }
  },
  created () {
    this.handlerLoadDeliveryorderList()
  },
  methods: {
    ...mapActions([
      'findDeliveryorderPage'
    ]),
    toExport () {
      http.post('/contract/deliveryorder/export', this.form, {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
        responseType: 'blob'
      }).then(data => {
        if (data && data.size > 0) {
          const blob = new Blob([ data ], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          })
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob)
          } else {
            const link = document.createElement('a')
            link.style.display = 'none'
            console.log(blob)
            link.href = URL.createObjectURL(blob)
            link.download = '后台发货单列表.xls'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },
    handlerLoadDeliveryorderList () {
      this.findDeliveryorderPage(this.form).then(({ list, total }) => {
        this.list = [ ...list || [] ]
        console.log(this.list)
        this.total = total || 0
      })
    },
    dialogDetails (data) {
      this.statusDetails = true
      this.$refs.details.refreshData(data)
    },
    handlerPaging (data) {
      Object.assign(this.form, data)
      this.handlerLoadDeliveryorderList()
    }
  }
}
</script>
