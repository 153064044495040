
import Vue from 'vue'
import { ExtendRouter, util } from 'security-view-deal'
import { local, Tools } from 'me-view'
Vue.use(ExtendRouter)

util.ignoringWatermarkPush([ '/', '/login' ])

// 动态获取路由： 根据目录结构
const filePaths = require.context('../views/', true, /.route.vue$/).keys()
const routeList = []
const children = filePaths.flatMap(item => {
  const path = Tools.pathConvert(item.replace('./', '/').replace('.route.vue', ''))
  const filePath = item.replace('./', '')
  routeList.push(path)
  return {
    path,
    component: () => import('@views/' + filePath)
  }
})
local.set('routeList', routeList)

export default new ExtendRouter({
  mode: 'history',
  routes: [
    { path: '/', redirect: '/home' },
    ...children,
    { path: '/home', component: () => import('@views/Home.vue') },
    { path: '/contract/uploadTemplate', component: () => import('@views/contract/uploadTemplate/UploadTemplate.vue') }
  ]
})
