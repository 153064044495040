import { http } from 'security-view-deal'

export default {
  insert (params) {
    return http.post('/contract/contractdetail/insert/selective', params)
  },
  update (params) {
    return http.put('/contract/contractdetail/update/selective/primary', params)
  },
  remove (params) {
    return http.delete('/contract/contractdetail/delete/primary', params)
  },
  findOne (params) {
    return http.get('/contract/contractdetail/vo/find', params)
  },
  selectByPrimary (params) {
    return http.get('/contract/contractdetail/select/primary', params)
  },
  selectPage (params) {
    return http.get('/contract/contractdetail/paged', params)
  },
  findList (params) {
    return http.get('/contract/contractdetail/vo/list', params)
  },
  findPage (params) {
    return http.get('/contract/contractdetail/vo/paged', params)
  },
  batchRemove (params) {
    return http.delete('/contract/contractdetail/batch/delete', params)
  }
}
