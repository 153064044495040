import api from '@http/contract/Deliveryorder.api'
export default {
  insertDeliveryorder (options, payload) {
    return api.insert(payload)
  },
  updateDeliveryorder (options, payload) {
    return api.update(payload)
  },
  removeDeliveryorder (options, payload) {
    return api.remove(payload)
  },
  batchRemoveDeliveryorder (options, payload) {
    return api.batchRemove(payload)
  },
  selectDeliveryorderByPrimary (options, payload) {
    return api.selectByPrimary(payload)
  },
  findDeliveryorder (options, payload) {
    return api.findOne(payload)
  },
  findDeliveryorderList (options, payload) {
    return api.findList(payload)
  },
  selectDeliveryorderPage (options, payload) {
    return api.selectPage(payload)
  },
  findDeliveryorderPage (options, payload) {
    return api.findPage(payload)
  }
}
