<template>
  <me-form class="me-grid-column-4" :rules="rules" ref="form">
    <me-label width="300px" title="支付方式" prop="iacctpaytype">
      <me-input clearable v-model="form.iacctpaytype" />
    </me-label>
    <me-label width="300px" title="费用业务类型" prop="ifeetype">
      <me-input clearable v-model="form.ifeetype" />
    </me-label>
    <me-label width="300px" title="收费项目" prop="sfeeitem">
      <me-input clearable v-model="form.sfeeitem" />
    </me-label>
    <me-label width="300px" title="补充说明" prop="sfeeremark">
      <me-input clearable v-model="form.sfeeremark" />
    </me-label>
    <me-label width="300px" title="收费金额" prop="famount">
      <me-input clearable v-model="form.famount" />
    </me-label>
    <me-label width="300px" title="支付状态" prop="ipaystatus">
      <me-input clearable v-model="form.ipaystatus" />
    </me-label>
    <me-label width="300px" title="交易状态" prop="itradestatus">
      <me-input clearable v-model="form.itradestatus" />
    </me-label>
    <me-label width="300px" title="发起方" prop="isendpoint">
      <me-input clearable v-model="form.isendpoint" />
    </me-label>
    <me-label width="300px" title="作废类型" prop="icanceltype">
      <me-input clearable v-model="form.icanceltype" />
    </me-label>
    <me-label width="300px" title="作废原因" prop="scancelremark">
      <me-input clearable v-model="form.scancelremark" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        sguid: '',
        iacctpaytype: '',
        ifeetype: '',
        sfeeitem: '',
        sfeeremark: '',
        famount: '',
        ipaystatus: '',
        itradestatus: '',
        isendpoint: '',
        icanceltype: '',
        scancelremark: ''
      },
      rules: {
        sguid: [
          { required: true, message: '主键不能为空' }
        ],
        iacctpaytype: [
          { required: true, message: '支付方式不能为空' }
        ],
        ifeetype: [
          { required: true, message: '费用业务类型不能为空' }
        ],
        sfeeitem: [
          { required: true, message: '收费项目不能为空' }
        ],
        sfeeremark: [
          { required: true, message: '补充说明不能为空' }
        ],
        famount: [
          { required: true, message: '收费金额不能为空' }
        ],
        ipaystatus: [
          { required: true, message: '支付状态不能为空' }
        ],
        itradestatus: [
          { required: true, message: '交易状态不能为空' }
        ],
        isendpoint: [
          { required: true, message: '发起方不能为空' }
        ],
        icanceltype: [
          { required: true, message: '作废类型不能为空' }
        ],
        scancelremark: [
          { required: true, message: '作废原因不能为空' }
        ]
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectContractfeeByPrimary', 'updateContractfee' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectContractfeeByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
      }
    },
    async save () {
      await this.$refs.form.validate()
      await this.updateContractfee(this.form)
    },
    reset () {
      this.$refs.form.reset()
    }
  }
}
</script>
