export default {
  setContractfeeList (state, payload) {
    state.contractfeeList = [ ...payload ]
  },
  setContractfee (state, payload) {
    state.contractfee = { ...payload }
  },
  setContractfeeTotal (state, payload) {
    state.contractfeeTotal = payload
  }
}
