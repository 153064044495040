<template>
  <me-form class="me-grid-column-4" ref="form">
    <me-label width="300px" title="开票编号" prop="scode">
      <me-input readonly v-model="form.scode" />
    </me-label>
    <me-label width="300px" title="开票量" prop="finvoiceweight">
      <me-input readonly v-model="form.finvoiceweight" />
    </me-label>
    <me-label width="300px" title="进销金额" prop="finvoicemoney">
      <me-input readonly v-model="form.finvoicemoney" />
    </me-label>
    <me-label width="300px" title="开票单价" prop="finvoiceprice">
      <me-input readonly v-model="form.finvoiceprice" />
    </me-label>
    <me-label width="300px" title="备注" prop="sremark">
      <me-input readonly v-model="form.sremark" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        sguid: '',
        scode: '',
        finvoiceweight: '',
        finvoicemoney: '',
        finvoiceprice: '',
        sremark: ''
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectReceiptinfodetailByPrimary' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectReceiptinfodetailByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
      }
    }
  }
}
</script>
