<template>
  <div class="me-column me-flex">
    <me-form class="me-row" ref="form">
      <me-label width="300px" title="操作" prop="sitem">
        <me-input clearable v-model="form.sitem" />
      </me-label>
      <me-label width="300px" title="操作人" prop="susername">
        <me-input clearable v-model="form.susername" />
      </me-label>
      <me-label width="300px" title="合同编号" prop="sobjno">
        <me-input clearable v-model="form.sobjno" />
      </me-label>
    </me-form>

    <me-table has-index class="me-flex" field-value="sguid" :columns="columns" :data="list" ref="table">
      <template #header>
        <div class="me-row me-flex">
          <div class="me-flex">&nbsp;</div>
          <div class="me-row">
            <me-button @click="handlerLoadContractList" type="primary" icon="icon-search">查 询</me-button>
            <me-button @click="reset" plain type="primary">重 置</me-button>
          </div>
        </div>
      </template>
      <template #footer>
        <me-paging :pageSize="form.pageSize" :total="total" class="me-flex" @change-page="handlerPaging" />
      </template>
    </me-table>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {},
  data() {
    return {
      columns: [
        { label: 'IP', field: 'sip', layout: 'center' },
        { label: '操作人', field: 'susername', layout: 'center' },
        { label: '操作', field: 'sitem', layout: 'center' },
        { label: '时间', field: 'doperatortime', layout: 'center' },
        { label: '合同编号', field: 'sobjno', layout: 'center' }
      ],
      form: {
        sobjno: '',
        sitem: '',
        susername: '',
        itype: 40,
        pageSize: 15,
        pageNum: 1
      },
      list: [],
      total: 0
    }
  },
  created() {
    this.handlerLoadContractList()
  },
  methods: {
    ...mapActions([ 'selectFrontLog' ]),
    handlerLoadContractList () {
      this.selectFrontLog(this.form).then(({ list, total }) => {
        this.list = [ ...list || [] ]
        this.total = total || 0
      })
    },
    reset () {
      this.$refs.form.reset()
      this.handlerLoadContractList()
    },
    handlerPaging (data) {
      Object.assign(this.form, data)
      this.handlerLoadContractList()
    }

  }
}
</script>
