<template>
  <me-form class="me-grid-column-4" :rules="rules" ref="form">
    <me-label width="300px" title="品种名称" prop="scategory">
      <me-input clearable v-model="form.scategory" />
    </me-label>
    <me-label width="300px" title="规格" prop="specification">
      <me-input clearable v-model="form.specification" />
    </me-label>
    <me-label width="300px" title="重量" prop="fweight">
      <me-input clearable v-model="form.fweight" />
    </me-label>
    <me-label width="300px" title="数量单位" prop="sweightunit">
      <me-input clearable v-model="form.sweightunit" />
    </me-label>
    <me-label width="300px" title="货价" prop="fprice">
      <me-input clearable v-model="form.fprice" />
    </me-label>
    <me-label width="300px" title="件数" prop="inumber">
      <me-input clearable v-model="form.inumber" />
    </me-label>
    <me-label width="300px" title="单件重量" prop="fnumberweight">
      <me-input clearable v-model="form.fnumberweight" />
    </me-label>
    <me-label width="300px" title="开单件数" prop="iordernumber">
      <me-input clearable v-model="form.iordernumber" />
    </me-label>
    <me-label width="300px" title="开单重量" prop="forderweight">
      <me-input clearable v-model="form.forderweight" />
    </me-label>
    <me-label width="300px" title="交收件数" prop="ioutnumber">
      <me-input clearable v-model="form.ioutnumber" />
    </me-label>
    <me-label width="300px" title="交收重量" prop="foutweight">
      <me-input clearable v-model="form.foutweight" />
    </me-label>
    <me-label width="300px" title="交收单价" prop="foutprice">
      <me-input clearable v-model="form.foutprice" />
    </me-label>
    <me-label width="300px" title="交收总额" prop="foutamount">
      <me-input clearable v-model="form.foutamount" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      formDefault: {
        scategory: '',
        specification: '',
        sguid: '',
        fweight: '',
        sweightunit: '',
        fprice: '',
        inumber: '',
        fnumberweight: '',
        iordernumber: '',
        forderweight: '',
        ioutnumber: '',
        foutweight: '',
        foutprice: '',
        foutamount: ''
      },
      form: { ...this.formDefault },
      rules: {
        scategory: [
          { required: true, message: '品种名称不能为空' }
        ],
        specification: [
          { required: true, message: '规格不能为空' }
        ],
        sguid: [
          { required: true, message: '主键不能为空' }
        ],
        fweight: [
          { required: true, message: '重量不能为空' }
        ],
        sweightunit: [
          { required: true, message: '数量单位不能为空' }
        ],
        fprice: [
          { required: true, message: '货价不能为空' }
        ],
        inumber: [
          { required: true, message: '件数不能为空' }
        ],
        fnumberweight: [
          { required: true, message: '单件重量不能为空' }
        ],
        iordernumber: [
          { required: true, message: '开单件数不能为空' }
        ],
        forderweight: [
          { required: true, message: '开单重量不能为空' }
        ],
        ioutnumber: [
          { required: true, message: '交收件数不能为空' }
        ],
        foutweight: [
          { required: true, message: '交收重量不能为空' }
        ],
        foutprice: [
          { required: true, message: '交收单价不能为空' }
        ],
        foutamount: [
          { required: true, message: '交收总额不能为空' }
        ]
      }
    }
  },
  methods: {
    ...mapActions([ 'selectContractdetailByPrimary', 'insertContractdetail' ]),
    async save () {
      await this.$refs.form.validate()
      await this.insertContractdetail(this.form)
    },
    async refreshData ({ sguid }) {
      this.form = { ...this.formDefault }
      if (this.$tools.notBlank(sguid)) {
        const data = await this.selectContractdetailByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data, sguid: undefined })
      }
    },
    reset () {
      this.$refs.form.reset()
    }
  }
}
</script>
