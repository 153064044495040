export default {
  setReceiptinfodetailList (state, payload) {
    state.receiptinfodetailList = [ ...payload ]
  },
  setReceiptinfodetail (state, payload) {
    state.receiptinfodetail = { ...payload }
  },
  setReceiptinfodetailTotal (state, payload) {
    state.receiptinfodetailTotal = payload
  }
}
