<template>
  <me-form class="me-grid-column-4" ref="form">
    <me-label width="300px" title="品种名称" prop="scategory">
      <me-input readonly v-model="form.scategory" />
    </me-label>
    <me-label width="300px" title="规格" prop="specification">
      <me-input readonly v-model="form.specification" />
    </me-label>
    <me-label width="300px" title="重量" prop="fweight">
      <me-input readonly v-model="form.fweight" />
    </me-label>
    <me-label width="300px" title="数量单位" prop="sweightunit">
      <me-input readonly v-model="form.sweightunit" />
    </me-label>
    <me-label width="300px" title="货价" prop="fprice">
      <me-input readonly v-model="form.fprice" />
    </me-label>
    <me-label width="300px" title="件数" prop="inumber">
      <me-input readonly v-model="form.inumber" />
    </me-label>
    <me-label width="300px" title="单件重量" prop="fnumberweight">
      <me-input readonly v-model="form.fnumberweight" />
    </me-label>
    <me-label width="300px" title="开单件数" prop="iordernumber">
      <me-input readonly v-model="form.iordernumber" />
    </me-label>
    <me-label width="300px" title="开单重量" prop="forderweight">
      <me-input readonly v-model="form.forderweight" />
    </me-label>
    <me-label width="300px" title="交收件数" prop="ioutnumber">
      <me-input readonly v-model="form.ioutnumber" />
    </me-label>
    <me-label width="300px" title="交收重量" prop="foutweight">
      <me-input readonly v-model="form.foutweight" />
    </me-label>
    <me-label width="300px" title="交收单价" prop="foutprice">
      <me-input readonly v-model="form.foutprice" />
    </me-label>
    <me-label width="300px" title="交收总额" prop="foutamount">
      <me-input readonly v-model="form.foutamount" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        scategory: '',
        specification: '',
        sguid: '',
        fweight: '',
        sweightunit: '',
        fprice: '',
        inumber: '',
        fnumberweight: '',
        iordernumber: '',
        forderweight: '',
        ioutnumber: '',
        foutweight: '',
        foutprice: '',
        foutamount: ''
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectContractdetailByPrimary' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectContractdetailByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
      }
    }
  }
}
</script>
