export default {
  setBillpayList (state, payload) {
    state.billpayList = [ ...payload ]
  },
  setBillpay (state, payload) {
    state.billpay = { ...payload }
  },
  setBillpayTotal (state, payload) {
    state.billpayTotal = payload
  }
}
