<template>
  <me-form class="me-grid-column-4" :rules="rules" ref="form">
    <me-label width="300px" title="支付单号" prop="spayno">
      <me-input clearable v-model="form.spayno" />
    </me-label>
    <me-label width="300px" title="支付方式" prop="iacctpaytype">
      <me-input clearable v-model="form.iacctpaytype" />
    </me-label>
    <me-label width="300px" title="支付状态" prop="ipaystatus">
      <me-input clearable v-model="form.ipaystatus" />
    </me-label>
    <me-label width="300px" title="交易状态" prop="itradestatus">
      <me-input clearable v-model="form.itradestatus" />
    </me-label>
    <me-label width="300px" title="支付金额" prop="famount">
      <me-input clearable v-model="form.famount" />
    </me-label>
    <me-label width="300px" title="作废类型" prop="icanceltype">
      <me-input clearable v-model="form.icanceltype" />
    </me-label>
    <me-label width="300px" title="作废原因" prop="scancelremark">
      <me-input clearable v-model="form.scancelremark" />
    </me-label>
    <me-label width="300px" title="支付业务参数" prop="spaybizparam">
      <me-input clearable v-model="form.spaybizparam" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        spayno: '',
        iacctpaytype: '',
        ipaystatus: '',
        itradestatus: '',
        famount: '',
        icanceltype: '',
        scancelremark: '',
        spaybizparam: ''
      },
      rules: {
        spayno: [
          { required: true, message: '支付单号不能为空' }
        ],
        iacctpaytype: [
          { required: true, message: '支付方式不能为空' }
        ],
        ipaystatus: [
          { required: true, message: '支付状态不能为空' }
        ],
        itradestatus: [
          { required: true, message: '交易状态不能为空' }
        ],
        famount: [
          { required: true, message: '支付金额不能为空' }
        ],
        icanceltype: [
          { required: true, message: '作废类型不能为空' }
        ],
        scancelremark: [
          { required: true, message: '作废原因不能为空' }
        ],
        spaybizparam: [
          { required: true, message: '支付业务参数不能为空' }
        ]
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectBillpayByPrimary', 'updateBillpay' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectBillpayByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
      }
    },
    async save () {
      await this.$refs.form.validate()
      await this.updateBillpay(this.form)
    },
    reset () {
      this.$refs.form.reset()
    }
  }
}
</script>
