export default {
  ladingorderdetailList (state) {
    return state.ladingorderdetailList
  },
  ladingorderdetail (state) {
    return state.ladingorderdetail
  },
  ladingorderdetailTotal (state) {
    return state.ladingorderdetailTotal
  }
}
