export default {
  ladingorderList (state) {
    return state.ladingorderList
  },
  ladingorder (state) {
    return state.ladingorder
  },
  ladingorderTotal (state) {
    return state.ladingorderTotal
  }
}
