import api from '@http/contract/Receiptinfodetail.api'
export default {
  insertReceiptinfodetail (options, payload) {
    return api.insert(payload)
  },
  updateReceiptinfodetail (options, payload) {
    return api.update(payload)
  },
  removeReceiptinfodetail (options, payload) {
    return api.remove(payload)
  },
  batchRemoveReceiptinfodetail (options, payload) {
    return api.batchRemove(payload)
  },
  selectReceiptinfodetailByPrimary (options, payload) {
    return api.selectByPrimary(payload)
  },
  findReceiptinfodetail (options, payload) {
    return api.findOne(payload)
  },
  findReceiptinfodetailList (options, payload) {
    return api.findList(payload)
  },
  selectReceiptinfodetailPage (options, payload) {
    return api.selectPage(payload)
  },
  findReceiptinfodetailPage (options, payload) {
    return api.findPage(payload)
  }
}
