<template>
  <me-form ref="form" style="width: 800px">
    <me-row>
      <me-label title="合同编号" >
        <me-input readonly v-model="form.scontractno" />
      </me-label>
      <me-label title="线下合同编号" >
        <me-input readonly v-model="form.soffcontractno" />
      </me-label>
    </me-row>

    <me-row>
      <me-label title="买方会员" >
        <me-input readonly v-model="form.sbuyermembername" />
      </me-label>
      <me-label title="卖方会员" >
        <me-input readonly v-model="form.ssellermembername" />
      </me-label>
    </me-row>

    <me-label title="作废原因 " >
      <me-textarea readonly v-model="form.sinvalidatereason" />
    </me-label>

    <me-label title="作废合同附件" >
    <el-link :href="utils.repairFileUrl(form.sfileurl)" class="m-r-8" target="_blank" type="primary" style="margin-top: 6px;">
      下载
    </el-link>
    </me-label>

  </me-form>
</template>
<script>
import { mapActions } from 'vuex'
import utils from '@/assets/script/utils'
export default {
  data () {
    return {
      utils: utils,
      form: {
        sguid: '',
        sbuyermembername: '',
        ssellermembername: '',
        scontractno: '',
        soffcontractno: '',
        sinvalidatereason: '',
        sfileurl: ''
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  computed: {

  },
  methods: {
    ...mapActions([ 'selectContractByPrimary', 'checkDisableAgree', 'checkDisableReject' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectContractByPrimary({ key: sguid })

        this.$tools.assign(this.form, { ...data })
        if (data.sjson) {
          const fileJson = JSON.parse(data.sjson)
          this.form.sfileurl = fileJson.disablefile[0]
        }
      }
    },
    async agree () {
      await this.$refs.form.validate()
      await this.checkDisableAgree(this.form)
    },
    async reject () {
      await this.$refs.form.validate()
      await this.checkDisableReject(this.form)
    }
  }
}
</script>
<style lang="scss" scoped>
.picture {
  width: 200px;
  height: 100px;
}
.margin-h {
  height: 40px;
  > * {
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
