export default {
  setDeliveryorderList (state, payload) {
    state.deliveryorderList = [ ...payload ]
  },
  setDeliveryorder (state, payload) {
    state.deliveryorder = { ...payload }
  },
  setDeliveryorderTotal (state, payload) {
    state.deliveryorderTotal = payload
  }
}
