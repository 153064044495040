var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('me-table',{staticClass:"me-flex",attrs:{"has-index":"","field-value":"sguid","columns":_vm.columns,"data":_vm.list},scopedSlots:_vm._u([{key:"iacctpaytype",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s({0: '在线支付', 1: '线下支付', 2: '拉卡拉支付'}[data.iacctpaytype] || '未知')+" ")]}},{key:"ipaytype",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s({10: '合同', 20: '项目结算单', 30: '代采合同', 40: '分期', 1000: '其他'}[data.ipaytype] || '未知')+" ")]}},{key:"ipaystatus",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s({10: '待支付', 20: '支付中', 30: '支付完成', 40: '支付失败', 99: '作废'}[data.ipaystatus] || '未知')+" ")]}},{key:"itradestatus",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s({0: '未提交', 1: '支付中', 2: '支付完成', 3: '支付失败'}[data.itradestatus] || '未知')+" ")]}},{key:"isendpoint",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s({1: '支付方', 2: '收款方', 3: '平台', 4: '系统'}[data.isendpoint] || '未知')+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }