import { ExtendHttp, http } from 'security-view-deal'

export default {
  insert (params) {
    return http.post('/contract/billpay/insert/selective', params)
  },
  update (params) {
    return http.put('/contract/billpay/update/selective/primary', params)
  },
  remove (params) {
    return http.delete('/contract/billpay/delete/primary', params)
  },
  findOne (params) {
    return http.get('/contract/billpay/vo/find', params)
  },
  selectByPrimary (params) {
    return http.get('/contract/billpay/select/primary', params)
  },
  selectPage (params) {
    return http.get('/contract/billpay/paged', params)
  },
  findList (params) {
    return http.get('/contract/billpay/vo/list', params)
  },
  findPage (params) {
    return http.get('/contract/billpay/vo/paged', params)
  },
  batchRemove (params) {
    return http.delete('/contract/billpay/batch/delete', params)
  },
  getDetails (params) {
    return http.post('/contract/billpay/getDetails', params, ExtendHttp.HEADER_JSON)
  },
  billpayPage (params) {
    return http.post('/contract/billpay/billPayList', params, ExtendHttp.HEADER_JSON)
  },
  selectList (params) {
    return http.post('/contract/billpay/getBillpaysWithDetail', params, ExtendHttp.HEADER_JSON)
  }
}
