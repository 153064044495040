<template>
  <div class="me-column me-flex">
    <me-form class="me-row" ref="form">
      <me-label width="300px" title="站点来源" prop="substation">
        <me-combo-select clearable :data="$dictList('SUB_LAIYUAN')" readonly v-model="form.substation" placeholder="请下拉选择"/>
      </me-label>
      <me-label width="300px" title="提货单编号" prop="swarehouseoutno">
        <me-input clearable v-model="form.swarehouseoutno" />
      </me-label>
      <me-label width="300px" title="合同编号" prop="scontractno">
        <me-input clearable v-model="form.scontractno" />
      </me-label>
      <me-label width="300px" title="提货人" prop="sdrivername">
        <me-input clearable v-model="form.sdrivername" />
      </me-label>

    </me-form>
    <me-form class="me-row" ref="form">
      <me-label width="300px" title="提货单状态" prop="istatus">
        <me-combo-select :data="$dictList('LADING_ORDER__STATUS')" clearable readonly v-model="form.istatus" />
      </me-label>
      <me-label width="300px" title="提货进度" prop="ideliverystatus">
        <me-combo-select :data="$dictList('LADING_ORDER__PROGRESS')" clearable readonly v-model="form.ideliverystatus" />
      </me-label>
    </me-form>
    <me-line-h />

    <me-table class="me-flex" field-value="sguid" :columns="columns" :data="list" has-index ref="table">
      <template #header>
        <div class="me-row me-flex">
          <div class="me-flex">&nbsp;</div>
          <div class="me-row">
            <me-button @click="toExport()" size="small" type="primary">导出Excel</me-button>
            <me-button @click="handlerLoadLadingorderList" type="primary" icon="icon-search">查 询</me-button>
            <me-button @click="$refs.form.reset" plain type="primary">重 置</me-button>
          </div>
        </div>
      </template>
      <template #substation="{data}">
        {{$dictValue('SUB_LAIYUAN', data.substation + '')}}
      </template>
      <template #istatus="{data}"> {{$dictValue('LADING_ORDER__STATUS', data.istatus)}} </template>
      <template #ideliverystatus="{data}"> {{$dictValue('LADING_ORDER__PROGRESS', data.ideliverystatus)}} </template>
      <template #action="{data}">
        <me-icon @click="dialogDetails(data)" title="详情">icon-chakan</me-icon>
      </template>
      <template #footer>
        <me-paging :pageSize="form.pageSize" :total="total" class="me-flex" @change-page="handlerPaging" />
      </template>
    </me-table>
    <me-dialog title="详情" hidden-footer v-model="statusDetails">
      <ladingorder-details ref="details" />
    </me-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LadingorderDetails from './Details'
import { http } from 'security-view-deal'

export default {
  components: { LadingorderDetails },
  data () {
    return {
      statusDetails: false,
      columns: [
        { label: '操作', field: 'action', layout: 'center', width: '100px' },
        { label: '来源', field: 'substation', layout: 'center' },
        { label: '提货单编号', field: 'sorderno', layout: 'center' },
        { label: '合同编号', field: 'scontractno', layout: 'center' },
        { label: '卖方会员', field: 'ssellermember', layout: 'center' },
        { label: '买方会员', field: 'sbuyermember', layout: 'center' },
        { label: '提货量', field: 'fweight', layout: 'center' },
        { label: '提货手机', field: 'smobile', layout: 'center' },
        { label: '车牌号', field: 'scarno', layout: 'center' },
        { label: '提货总额', field: 'famount', layout: 'center' },
        { label: '提货进度', field: 'ideliverystatus', layout: 'center' },
        { label: '仓库', field: 'swarehouse' },
        { label: '状态', field: 'istatus', layout: 'center' }

      ],
      form: {
        swarehouseoutno: '',
        scontractno: '',
        sdrivername: '',
        istatus: null,
        ideliverystatus: '',
        pageSize: 15,
        pageNum: 1
      },
      list: [],
      total: 0
    }
  },
  created () {
    this.handlerLoadLadingorderList()
  },
  methods: {
    ...mapActions([
      'findLadingorderPage'
    ]),
    toExport () {
      http.post('/contract/ladingorder/export', this.form, {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
        responseType: 'blob'
      }).then(data => {
        if (data && data.size > 0) {
          const blob = new Blob([ data ], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          })
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob)
          } else {
            const link = document.createElement('a')
            link.style.display = 'none'
            console.log(blob)
            link.href = URL.createObjectURL(blob)
            link.download = '后台提货单列表.xls'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },
    handlerLoadLadingorderList () {
      this.findLadingorderPage(this.form).then(({ list, total }) => {
        this.list = [ ...list || [] ]
        this.total = total || 0
      })
    },
    dialogDetails (data) {
      this.statusDetails = true
      this.$refs.details.refreshData(data)
    },
    handlerPaging (data) {
      Object.assign(this.form, data)
      this.handlerLoadLadingorderList()
    }
  }
}
</script>
