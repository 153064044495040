export default {
  setLadingorderList (state, payload) {
    state.ladingorderList = [ ...payload ]
  },
  setLadingorder (state, payload) {
    state.ladingorder = { ...payload }
  },
  setLadingorderTotal (state, payload) {
    state.ladingorderTotal = payload
  }
}
