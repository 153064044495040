export default {
  deliveryorderList (state) {
    return state.deliveryorderList
  },
  deliveryorder (state) {
    return state.deliveryorder
  },
  deliveryorderTotal (state) {
    return state.deliveryorderTotal
  }
}
