<template>
  <div>
    <me-panel layout="row" title="基本信息">
      <me-form class="me-grid-column-3" ref="form">
        <!-- <me-label width="300px" title="仓库发货单号" prop="swarehouseoutno">
          <me-input readonly v-model="form.swarehouseoutno" />
        </me-label> -->
        <me-label width="300px" title="发货单编号" prop="sorderno">
          <me-input readonly v-model="form.sorderno"/>
        </me-label>
        <me-label width="300px" title="发货状态" prop="istatus">
          <me-combo-select :data="$dictList('DELIVER_ORDER__STATUS')" disabled v-model="form.istatus"/>
        </me-label>
        <me-label width="300px" title="发货进度" prop="ideliverystatus">
          <me-combo-select :data="$dictList('DELIVER_ORDER__PROGRESS')" disabled v-model="form.ideliverystatus"/>
        </me-label>
        <me-label width="300px" title="物流公司" prop="slogistics">
          <me-input readonly v-model="form.slogistics"/>
        </me-label>
        <me-label width="300px" title="运单号" prop="swaybillno">
          <me-input readonly v-model="form.swaybillno"/>
        </me-label>
        <me-label width="300px" title="发货仓库" prop="swarehouse">
          <me-input readonly v-model="form.swarehouse"/>
        </me-label>
        <me-label width="300px" title="卖方会员" prop="ssellermember">
          <me-input readonly v-model="form.ssellermember"/>
        </me-label>
      </me-form>
    </me-panel>
    <div v-if="fileList.length > 0">
      <me-panel layout="row" title="附件信息">
        <el-table :data="fileList" border>
          <el-table-column label="附件">
            <template slot-scope="scope">
              <el-image v-if="utils.isImage(scope.row.fileUrl)"
                        style="width: 100px; height: 100px"
                        :src="utils.repairFileUrl(scope.row.fileUrl)"
                        :preview-src-list="[utils.repairFileUrl(scope.row.fileUrl)]">
              </el-image>
              <p v-else>{{ scope.row }}</p>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="type"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link :href="utils.repairFileUrl(scope.row.fileUrl)" target="_blank" type="primary">下载</el-link>
            </template>
          </el-table-column>
        </el-table>
      </me-panel>
    </div>
    <me-panel layout="row" title="发货信息">
      <me-table has-index class="me-flex" :columns="columns" :data="detailList">
      </me-table>
    </me-panel>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import deliveryorderdetailApi from '../../../http/contract/Deliveryorderdetail.api'
import utils from '@/assets/script/utils'

export default {
  data() {
    return {
      utils: utils,
      form: {
        sguid: '',
        swarehouseoutno: '',
        sorderno: '',
        ideliverystatus: '',
        istatus: '',
        bischecked: '',
        slogistics: '',
        swaybillno: '',
        swarehouse: '',
        ssellermember: ''
      },
      columns: [
        { label: '开单重量', field: 'foderweight', layout: 'center' },
        { label: '开单件数', field: 'iorderpackage', layout: 'center' },
        { label: '发货重量', field: 'fsendweight', layout: 'center' },
        { label: '发货件数', field: 'isendpackage', layout: 'center' },
        { label: '是否出库完成', field: 'bisfinish', layout: 'center' },
        { label: '出库单价', field: 'fprice', layout: 'center' }
      ],
      detailList: [],
      fileList: []
    }
  },
  created() {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectDeliveryorderByPrimary' ]),
    async refreshData({ sguid }) {
      if (sguid) {
        const data = await this.selectDeliveryorderByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
        this.fileList = []
        const file = {}
        if (JSON.parse(data.sjson).seller) {
          JSON.parse(data.sjson).seller.forEach(item => {
            file.type = '卖家'
            file.fileUrl = item
            this.fileList.push(file)
          })
        }
        if (JSON.parse(data.sjson).buyer) {
          JSON.parse(data.sjson).buyer.forEach(item => {
            file.type = '买家'
            file.fileUrl = item
            this.fileList.push(file)
          })
        }

        const detail = await deliveryorderdetailApi.findList({ sorderid: sguid })
        detail.forEach(item => {
          if (item.bisfinish === 0) {
            item.bisfinish = '未完成'
          }
          if (item.bisfinish === 1) {
            item.bisfinish = '已完成'
          }
        })
        this.detailList = [ ...detail || [] ]
      }
    }
  }
}
</script>
