<template>
  <me-form class="me-grid-column-4" ref="form">
    <me-label width="300px" title="合同ID" prop="scontractid">
      <me-input readonly v-model="form.scontractid" />
    </me-label>
    <me-label width="300px" title="提单ID" prop="sorderid">
      <me-input readonly v-model="form.sorderid" />
    </me-label>
    <me-label width="300px" title="开单重量" prop="foderweight">
      <me-input readonly v-model="form.foderweight" />
    </me-label>
    <me-label width="300px" title="开单件数" prop="iorderpackage">
      <me-input readonly v-model="form.iorderpackage" />
    </me-label>
    <me-label width="300px" title="发货重量" prop="fsendweight">
      <me-input readonly v-model="form.fsendweight" />
    </me-label>
    <me-label width="300px" title="发货件数" prop="isendpackage">
      <me-input readonly v-model="form.isendpackage" />
    </me-label>
    <me-label width="300px" title="出库单价" prop="fprice">
      <me-input readonly v-model="form.fprice" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      form: {
        sguid: '',
        scontractid: '',
        sorderid: '',
        foderweight: '',
        iorderpackage: '',
        fsendweight: '',
        isendpackage: '',
        fprice: ''
      }
    }
  },
  created () {
    const sguid = this.$route.query.sguid
    this.refreshData({ sguid })
  },
  methods: {
    ...mapActions([ 'selectLadingorderdetailByPrimary' ]),
    async refreshData ({ sguid }) {
      if (sguid) {
        const data = await this.selectLadingorderdetailByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data })
      }
    }
  }
}
</script>
