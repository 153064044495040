export default {
  setContractdetailList (state, payload) {
    state.contractdetailList = [ ...payload ]
  },
  setContractdetail (state, payload) {
    state.contractdetail = { ...payload }
  },
  setContractdetailTotal (state, payload) {
    state.contractdetailTotal = payload
  }
}
